import { React } from "react";
import { Policy } from "../widgets";

const Terms = ({ language, checkPolicies, pageHeight }) => {
  return (
    <Policy
      language={language}
      checkPolicies={checkPolicies}
      policy="TC"
      pageHeight={pageHeight}
    />
  );
};

export default Terms;
