import { React } from "react";
import { useNavigate } from "react-router-dom";

const Solution = ({ language, params }) => {
  const navigate = useNavigate();

  const benefits = params.benefits.map((benefit, index) => (
    <>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          gap: "0.5em",
          marginTop: "1em",
        }}
      >
        <div className="successtext">✓</div>
        <div style={{ fontWeight: 400 }}>
          {language.website.benefits[benefit].heading}
        </div>
      </div>
      <div style={{ fontSize: "0.9em" }}>
        {language.website.benefits[benefit].text}
      </div>
    </>
  ));

  return (
    <div
      className={
        params.background ? "web-image-container" : "public websection"
      }
      style={
        params.background && {
          backgroundImage: `url(${params.background})`,
        }
      }
    >
      <div className={`public ${params.background && "websection"}`}>
        <div className="flex-switch-680px">
          {/* details */}
          <div
            className="card-public"
            style={params.mobile ? { flex: 1, margin: "0" } : { flex: 1 }}
          >
            <h2
              className="heading2"
              style={{
                fontSize: "2em",
                fontWeight: 500,
              }}
            >
              {language.website.solutions[params.page].heading}
            </h2>

            {params.thumb && (
              <img
                style={{ width: "160px", borderRadius: "6px" }}
                src={params.thumb}
              />
            )}

            <div
              dangerouslySetInnerHTML={{
                __html: language.website.solutions[params.page].details,
              }}
            />

            {params.image && (
              <img style={{ width: "100%" }} src={params.image} />
            )}

            <div style={{ margin: "0.5em 0" }}>
              {language.website.solutions[params.page].summary}
            </div>

            <button
              className="pill-button"
              onClick={() => {
                clearTimeout(params.timeoutRef);
                navigate("/contact");
              }}
              style={{ marginTop: "2em", fontSize: "1.1em" }}
            >
              <div style={{ fontWeight: 400 }}>
                {language.website.contact_sales}
              </div>
              <span className="glyphs">{">"}</span>
            </button>

            <div className="px900-hide">
              <div
                style={{
                  marginTop: "1.5em",
                  fontSize: "1.1em",
                  fontWeight: "500",
                }}
              >
                {language.website.solutions[params.page].benefits}
              </div>
              {benefits}
            </div>
          </div>

          {/* benefits */}
          <div className="card-public px900-show" style={{ flex: 0.8 }}>
            <div style={{ fontSize: "1.1em", fontWeight: "500" }}>
              {language.website.solutions[params.page].benefits}
            </div>
            {benefits}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solution;
