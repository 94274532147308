import { React } from "react";
import { useNavigate } from "react-router-dom";

const Collaboration = ({ language, params }) => {
  //////////////INITIALIZE //////////////
  const navigate = useNavigate();

  ////////////// RENDER GUI //////////////
  return (
    <div
      className={
        params.background ? "web-image-container" : "public websection"
      }
      style={
        params.background && {
          backgroundImage: `url(${params.background})`,
        }
      }
    >
      <div className={`public ${params.background && "websection"}`}>
        <div className="flex-switch-680px gapXL">
          <div className="public-header-container">
            <h2
              className={`heading2 font-size-XXL ${
                params.background ? "font-white" : "public-text-container"
              }`}
              style={{
                lineHeight: "initial",
                fontWeight: 500,
                padding: "1em 0",
              }}
            >
              {language.website.collaboration.heading}
            </h2>
            <button
              className={`pill-button mobile ${
                params.background ? "font-white" : "public-text-container"
              }`}
              onClick={() => {
                if (params.timeoutRef) clearTimeout(params.timeoutRef);
                navigate("/solutions");
              }}
              style={{ fontSize: "1.2em" }}
            >
              <div style={{ fontWeight: 400 }}>
                {language.website.explore_solutions}
              </div>
              <span className="glyphs">{">"}</span>
            </button>
          </div>

          <div
            className="card-public flex-switch-680px"
            style={{
              boxShadow: "none",
              fontSize: "1.1em",
              ...(params.background && { margin: 0, boxShadow: `none` }),
            }}
          >
            {language.website.collaboration.body}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collaboration;
