import { React } from "react";
import { Policy } from "../widgets";

const Privacy = ({ language, checkPolicies, pageHeight }) => {
  return (
    <Policy
      language={language}
      checkPolicies={checkPolicies}
      pageHeight={pageHeight}
    />
  );
};

export default Privacy;
