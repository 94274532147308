import { React, useState, useEffect } from "react";
import { Footer } from "../../pub/sections";
import { NavBar } from "..";

const Policy = ({ language, checkPolicies, policy, pageHeight }) => {
  const [title, setTitle] = useState("");
  const [html, setHtml] = useState("");

  useEffect(() => {
    getPolicy();
  }, []);

  //scroll to top of page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [html]);

  const getPolicy = async () => {
    let _policy = "PP";
    if (policy) _policy = policy;
    const _policies = await checkPolicies();
    var i = 0,
      len = _policies.length;
    while (i < len) {
      if (_policies[i].policy === _policy) {
        setTitle(_policies[i].title);
        setHtml(_policies[i].html);
        break;
      }
      i++;
    }
  };

  return (
    <main>
      <NavBar language={language} buttons={true} />

      <div className="public websection" style={{ padding: "4em 0" }}>
        <div className="card-public" style={{ paddingTop: "1em" }}>
          <h2 style={{ lineHeight: "initial" }}>{title}</h2>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>

      <div style={{ height: "6em" }} />
      <Footer language={language} params={{}} />
    </main>
  );
};

export default Policy;
