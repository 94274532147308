import { React, useEffect } from "react";

const SessionExpired = ({ language, params }) => {
  //////////// REMOVE NOTICE ///////////
  useEffect(() => {
    //fade
    setTimeout(() => {
      params.setExpiredOpacity(0);
    }, 2000);

    //remove
    setTimeout(() => {
      params.setSessionExpired(false);
      sessionStorage.removeItem("timedout");
    }, 2600);
  }, []);

  const dismiss = () => {
    params.setExpiredOpacity(0);
    setTimeout(() => {
      params.setSessionExpired(false);
      sessionStorage.removeItem("timedout");
    }, 600);
  };

  //////////// RENDER GUI ///////////
  return (
    <div
      style={{
        display: "flex",
        position: "fixed",
        top: "40px",
        left: "0",
        width: "100%",
        zIndex: 100,
        justifyContent: "center",
        opacity: params.expiredOpacity,
        transition: "0.5s",
      }}
    >
      <div className="expiry-notice">
        <div className="glyphs alert" style={{ fontSize: "1.2em" }}>
          !
        </div>
        <div>{language.labels.authentication.timedout}</div>
        <button className="glyphs" onClick={dismiss}>
          x
        </button>
      </div>
    </div>
  );
};
export default SessionExpired;
