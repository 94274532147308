import { React } from "react";
import { useNavigate } from "react-router-dom";

const PlanPublic = ({ language, params }) => {
  const navigate = useNavigate();

  return (
    <>
      <h3
        className={`${
          params.mobile && "font-white"
        } heading2 font-size-L card-heading`}
      >
        {language.website.pricing.custom.heading}
      </h3>
      <div className="flex-switch-680px">
        {/* details */}
        <div
          className="card-public"
          style={params.mobile ? { flex: 1, margin: "0" } : { flex: 1 }}
        >
          <div style={{ fontWeight: "500" }}>
            {language.website.pricing.custom.price}
          </div>
          <div>{language.website.pricing.custom.brief}</div>
          <div>{language.website.pricing.custom.details}</div>

          <button
            className="pill-button"
            onClick={() => {
              clearTimeout(params.timeoutRef);
              navigate("/contact");
            }}
            style={{ marginTop: "2em", fontSize: "1.1em" }}
          >
            <div style={{ fontWeight: 400 }}>
              {language.website.contact_sales}
            </div>
            <span className="glyphs">{">"}</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default PlanPublic;
