import config from "../../../config";
import validator from "../../../lib/validation";

const CommunityPublic = ({ community, language, auth }) => {
  ///////////////// INITIALIZE /////////////////

  return (
    <>
      {/* crest */}
      <div
        className={auth.account_type ? "mobile-center" : "web-center"}
        style={{ marginTop: "1.5em" }}
      >
        <img
          style={{
            borderRadius: "8px",
            width: "120px",
          }}
          src={config.server.storage.bucket + community.crest}
        />
      </div>

      {/* title */}
      <div
        className={auth.account_type ? "mobile-center" : "web-center"}
        style={{
          fontWeight: "500",
          marginTop: "0.5em",
        }}
      >
        {community.title}
      </div>

      {/* website */}
      {community.website && validator.URLchecker(community.website).host && (
        <div className={auth.account_type ? "mobile-center" : "web-center"}>
          <a
            href={community.website}
            target={community.uname}
            style={{ fontSize: "0.8em" }}
          >
            {validator.URLchecker(community.website).host}
          </a>
        </div>
      )}

      {/* verified */}
      {community.verified && (
        <div
          className={auth.account_type ? "mobile-justify" : "web-justify"}
          style={{
            display: "flex",
            gap: "0.25em",
            marginTop: "0.5em",
            alignItems: "center",
          }}
        >
          <div className="glyphs font-yongo" style={{ fontSize: "1.1em" }}>
            *
          </div>
          <div style={{ fontWeight: 400 }}>{language.labels.app.verified}</div>
        </div>
      )}

      {/* description */}
      <div
        style={{ marginTop: "0.25em" }}
        className={auth.account_type ? "mobile-center" : "web-center"}
      >
        {community.description}
      </div>

      {/* points */}
      <div
        className={auth.account_type ? "mobile-center" : "web-center"}
        style={{ marginTop: "1.5em", fontWeight: 400 }}
      >
        {community.points === 1
          ? language.labels.community.point
          : language.labels.community.points.replace(
              /{n}/g,
              validator.bigNumber(community.points || 0)
            )}
      </div>

      {/* stats */}
      <div
        className={auth.account_type ? "mobile-justify" : "web-justify"}
        style={{
          display: "flex",
          gap: "1em",
          marginTop: "0.5em",
          alignContent: "center",
        }}
      >
        <div style={{ fontSize: "0.9em" }}>
          {community.count_member === 1
            ? language.labels.community.member
            : language.labels.community.members.replace(
                /{n}/g,
                validator.bigNumber(community.count_member || 0)
              )}
        </div>
        <div style={{ fontSize: "0.9em" }}>|</div>
        <div style={{ fontSize: "0.9em" }}>
          {community.count_bite === 1
            ? language.labels.community.bite
            : language.labels.community.bites.replace(
                /{n}/g,
                validator.bigNumber(community.count_bite || 0)
              )}
        </div>
      </div>
    </>
  );
};
export default CommunityPublic;
