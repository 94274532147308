import { React, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { login } from "../../features/authentication";

import axios from "axios";
import config from "../../config";
import { NavBar } from "../widgets";
import Footer from "../layout/Footer";

const Terms = ({ language, signout, checkPolicies }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.authentication.value);

  const def_focus = useRef();

  useEffect(() => {
    def_focus.current.focus();
  }, []);

  const iAgree = () => {
    const configurationObject = {
      url: `${config.server.api}/policy/agree/hub/${localStorage.getItem(
        "client_id"
      )}`,
      method: "GET",
      withCredentials: true,
      credentials: "include",
    };

    document.getElementById("loader").style.display = "inline";
    axios(configurationObject)
      .then((response) => {
        document.getElementById("loader").style.display = "none";
        if (response.status === 200) {
          const _auth = { ...auth, agree_terms: "ok" };
          dispatch(login(_auth));
          navigate("/hub");
        } else {
          signout();
        }
      })
      .catch((error) => {
        document.getElementById("loader").style.display = "none";
        console.error(`LOGGED ERROR: \n`, error);
        signout();
      });
  };

  return (
    <>
      <NavBar language={language} />
      <main className="login">
        <h1
          className="focus-modest"
          style={{ marginTop: "3rem" }}
          ref={def_focus}
          tabIndex={0}
        >
          {auth.agree_terms === "never"
            ? language.labels.authentication.terms_review
            : language.labels.authentication.terms_updated}
        </h1>

        <button
          className="button"
          style={{ width: "100%", marginTop: "2rem", fontSize: "1.1rem" }}
          onClick={() => iAgree()}
        >
          {language.labels.authentication.terms_agreement}
        </button>

        <div className="join-container">
          <div
            role="link"
            className="link"
            style={{ fontSize: "0.8em", marginTop: "1em" }}
            onClick={() => signout()}
            onKeyUpCapture={(e) => e.key === "Enter" && signout()}
            tabIndex="0"
          >
            {language.labels.authentication.terms_not_agree}
          </div>
        </div>
      </main>

      <Footer
        language={language}
        checkPolicies={checkPolicies}
        ref={def_focus}
      />
    </>
  );
};

export default Terms;
