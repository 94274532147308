import { React, useEffect } from "react";
import { Footer } from "./sections";
import { NavBar } from "../widgets";
import config from "../../config";

const Support = ({ language, os }) => {
  //scroll to top of page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <NavBar language={language} buttons={true} />

      <div className="public websection" style={{ padding: "4em 0" }}>
        <div className="card-public" style={{ paddingTop: "1em" }}>
          <h2>{language.labels.app.support}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: language.labels.app.report_issue.replace(
                /{support}/g,
                config.email.support
              ),
            }}
          />
          <div style={{ marginTop: "1em" }}>
            {language.labels.app.report_app}
          </div>
        </div>
      </div>

      {/* bottom spacing and footer*/}
      <div style={{ height: "6em" }} />
      <Footer language={language} params={{ os }} />
    </main>
  );
};

export default Support;
