import { React, useState, useEffect, useRef } from "react";
import config from "../../../../config";
import validator from "../../../../lib/validation";
import axiosCall from "../../../../lib/axios";

import { Policies } from "../../../widgets";

const Key = ({
  checkPolicies,
  language,
  signout,
  animation,
  next,
  licenseKey,
  navigate,
  setLicenseKey,
}) => {
  //////////// INITIALIZATION ///////////
  const [errMsg, setErrMsg] = useState("");
  const key_input = useRef();

  useEffect(() => {
    if (animation && animation.step === 0) {
      if (animation.direction === 1) {
        document.getElementById("Key").style.animationName = "slidein";
      } else if (animation.direction === -1) {
        document.getElementById("Key").style.animationName = "slideout";
        document.getElementById("Key").style.animationDirection = "reverse";
      }
    }
  }, []);

  //////////// USER INTERACTIONS ///////////
  const contact_sales = (e) => {
    e.preventDefault();
    navigate("/contact");
  };

  const proceed = () => {
    document.getElementById("Key").style.animationName = "slideout";
    next({ step: 1, direction: 1 });
  };

  const nextStep = async (e) => {
    e.preventDefault();

    //reset error message
    setErrMsg("");

    //check licenseKey
    let _key = licenseKey.toLowerCase().trim();
    if (!_key) {
      setErrMsg(language.labels.error.key_invalid);
      key_input.current.focus();
      // setErrMsg(
      //   language.labels.error.key_activated.replace(
      //     /{sales}/g,
      //     config.email.sales
      //   )
      // );
      return;
    }

    // try and reserve uname
    let result = await axiosCall("community/register", { licenseKey: _key });
    console.log(result);
    if (result.success) {
      setErrMsg(language.labels.error.key_invalid);
      key_input.current.focus();

      // if (result.status === 200) {
      //   //proceed to next step
      //   document.getElementById("Key").style.animationName = "slideout";
      //   next({ step: 1, direction: 1 });
      // } else {
      //   setErrMsg(language.labels.error.community_taken);
      //   setReservedErr(true);
      // }
    } else if (result.refresh) {
      //token has been refreshed, try again
      nextStep(e);
    } else {
      setErrMsg(language.labels.error.key_invalid);
      key_input.current.focus();

      //refresh token expired or unknown error
      // signout();
    }
  };

  ////////////// RENDER GUI //////////////////
  return (
    <div className="mobile-margin" style={{ position: "relative" }}>
      <form
        className="block-step page-section mobile-margin"
        id="Key"
        onSubmit={(e) => nextStep(e)}
        aria-label={language.labels.aria.form.new_community}
        style={{ maxWidth: "44rem" }}
      >
        <div>{language.labels.communities.license.about}</div>

        <input
          ref={key_input}
          className={errMsg && "error"}
          value={licenseKey}
          onChange={(e) => {
            setLicenseKey(e.target.value);
          }}
          placeholder={language.labels.communities.license.enter_key}
          aria-placeholder={language.labels.communities.license.enter_key}
          style={{
            width: "100%",
            maxWidth: "44rem",
            display: "block",
            marginTop: "1em",
          }}
          maxLength={config.string.title}
        />
        {errMsg && (
          <div className="errtext" role="alert">
            {errMsg}
          </div>
        )}

        <div style={{ display: "flex", margin: "2em 0" }}>
          <button className="button" type="submit">
            {language.labels.app.action_next}
          </button>
        </div>

        <div style={{ display: "flex", gap: "0.5em" }}>
          <div>{language.labels.communities.license.no_key}</div>
          <button className="link" onClick={(e) => contact_sales(e)}>
            {language.website.contact_sales}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Key;
