import { React, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Footer,
  PlanCustom,
  PlanExpert,
  PlanPremium,
  PlanPublic,
} from "./sections";
import { NavBar } from "../widgets";
import background from "../../media/website/background/pexels-blitzboy-1106416-blur.jpg";
import config from "../../config";

const Pricing = ({ language, os }) => {
  //////////////INITIALIZE //////////////
  const navigate = useNavigate();

  //scroll to #section or top
  useEffect(() => {
    const scrollToSection = async () => {
      try {
        let section = "";
        if (window.location.hash)
          section = window.location.hash.replace(/#/, "");
        scrollSection(section);
      } catch (err) {
        window.scrollTo(0, 0);
      }
    };
    scrollToSection();
  }, []);

  const scrollSection = (section) => {
    //check for mobile section
    let sub = section;
    if (document.getElementById(section)) {
      if (document.getElementById(section).getBoundingClientRect().top === 0)
        sub = "m-" + section;
    }

    if (config.plans.includes(section) && document.getElementById(sub)) {
      let pos = document.getElementById(sub).getBoundingClientRect().top;
      console.log(pos);
      window.scrollTo({
        top: pos + window.scrollY,
        behavior: "smooth",
      });
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <main>
      <NavBar language={language} buttons={true} clear={true} />
      {/* landing */}
      <div
        className="web-image-container"
        style={{
          backgroundImage: `url(${background})`,
          marginTop: "-80px",
        }}
      >
        <div className="public landing-screen">
          <h2
            className="heading2 font-white font-size-L card-heading"
            style={{ fontWeight: 500 }}
          >
            {language.website.pricing.headline}
          </h2>

          {/* mobile only*/}
          <div className="px680-hide">
            <div
              className="font-white"
              style={{
                marginTop: "2em",
                fontSize: "1.4em",
                fontWeight: "400",
                lineHeight: "1.4em",
              }}
            >
              {language.website.pricing.blurb}
            </div>

            <button
              className="demo-request-button"
              onClick={() => {
                navigate("/contact");
              }}
              style={{ margin: "1.5em 0 -1em 0" }}
            >
              <div style={{ fontWeight: "500", lineHeight: "initial" }}>
                {language.website.contact_sales}
              </div>
              <span
                className="glyphs"
                style={{ fontWeight: "500", lineHeight: "initial" }}
              >
                {">"}
              </span>
            </button>

            {config.plans.map((plan, index) => (
              <div
                className="public websection"
                id={`m-${plan}`}
                style={
                  index < config.plans.length - 1
                    ? { paddingBottom: "0px" }
                    : {}
                }
              >
                {plan === "public" ? (
                  <PlanPublic language={language} params={{ mobile: true }} />
                ) : plan === "expert" ? (
                  <PlanExpert language={language} params={{ mobile: true }} />
                ) : plan === "premium" ? (
                  <PlanPremium language={language} params={{ mobile: true }} />
                ) : (
                  <PlanCustom language={language} params={{ mobile: true }} />
                )}
              </div>
            ))}
          </div>

          {/* desktop only*/}
          <div className="px680-show">
            <div className="card-public websection" style={{ margin: "2em 0" }}>
              <div
                className="font-size-M"
                style={{
                  fontWeight: "400",
                  lineHeight: "initial",
                }}
              >
                {language.website.pricing.blurb}
              </div>
              <button
                className="demo-request-button"
                onClick={() => {
                  navigate("/contact");
                }}
                style={{ margin: "1.5em 0 -1em 0" }}
              >
                <div style={{ fontWeight: "500", lineHeight: "initial" }}>
                  {language.website.contact_sales}
                </div>
                <span
                  className="glyphs"
                  style={{ fontWeight: "500", lineHeight: "initial" }}
                >
                  {">"}
                </span>
              </button>

              {/* tabs */}
              <div className="tab-set">
                {/* first two plans */}
                <div className="tab-set-group">
                  {config.plans.slice(0, 2).map((plan, index) => (
                    <div
                      className="hover"
                      onClick={() => scrollSection("public")}
                    >
                      <h3 className="heading2" style={{ fontSize: "1.4em" }}>
                        {language.website.pricing[plan].heading}
                      </h3>
                      <div style={{ fontWeight: "500" }}>
                        {language.website.pricing[plan].price ||
                          config.pricing[plan]}
                      </div>
                      <div>{language.website.pricing[plan].brief}</div>
                    </div>
                  ))}
                </div>
                {/*tab-set buttons */}
                <div
                  className="tab-set-group-buttons"
                  style={{ marginTop: "1em" }}
                >
                  {config.plans.slice(0, 2).map((plan, index) => (
                    <button
                      className="pill-button font-yongo"
                      onClick={() => {
                        scrollSection(plan);
                      }}
                    >
                      <div style={{ fontWeight: 400 }}>
                        {language.website.more}
                      </div>
                      <span className="glyphs">{">"}</span>
                    </button>
                  ))}
                </div>

                {/* last two plans */}
                <div className="tab-set-group">
                  {config.plans.slice(-2).map((plan, index) => (
                    <div
                      className="hover"
                      onClick={() => scrollSection("public")}
                    >
                      <h3 className="heading2" style={{ fontSize: "1.4em" }}>
                        {language.website.pricing[plan].heading}
                      </h3>
                      <div style={{ fontWeight: "500" }}>
                        {language.website.pricing[plan].price ||
                          config.pricing[plan]}
                      </div>
                      <div>{language.website.pricing[plan].brief}</div>
                    </div>
                  ))}
                </div>
                {/*tab-set buttons */}
                <div
                  className="tab-set-group-buttons"
                  style={{ marginTop: "1em" }}
                >
                  {config.plans.slice(-2).map((plan, index) => (
                    <button
                      className="pill-button font-yongo"
                      onClick={() => {
                        scrollSection(plan);
                      }}
                    >
                      <div style={{ fontWeight: 400 }}>
                        {language.website.more}
                      </div>
                      <span className="glyphs">{">"}</span>
                    </button>
                  ))}
                </div>
              </div>

              {/* all more info buttons */}
              <div className="tab-set-buttons" style={{ marginTop: "1em" }}>
                {config.plans.map((plan, index) => (
                  <button
                    className="pill-button font-yongo"
                    onClick={() => {
                      scrollSection(plan);
                    }}
                  >
                    <div style={{ fontWeight: 400 }}>
                      {language.website.more}
                    </div>
                    <span className="glyphs">{">"}</span>
                  </button>
                ))}
              </div>

              <div style={{ fontSize: "0.9em", paddingTop: "2em" }}>
                {language.website.pricing.per_month}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* sections - desktop only*/}
      <div className="px680-show">
        {config.plans.map((plan, index) => (
          <div
            className="public websection"
            id={plan}
            style={
              index < config.plans.length - 1 ? { paddingBottom: "0px" } : {}
            }
          >
            {plan === "public" ? (
              <PlanPublic language={language} params={{}} />
            ) : plan === "expert" ? (
              <PlanExpert language={language} params={{}} />
            ) : plan === "premium" ? (
              <PlanPremium language={language} params={{}} />
            ) : (
              <PlanCustom language={language} params={{}} />
            )}
          </div>
        ))}
      </div>

      {/* footer */}
      <Footer language={language} params={{ os, scrollSection }} />
    </main>
  );
};

export default Pricing;
