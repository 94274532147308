import ios from "../../media/ios-black.svg";
import android from "../../media/android-black.svg";
import alt_ios from "../../media/ios.svg";
import alt_android from "../../media/android.svg";
import config from "../../config";

const GetApp = ({ os, alt }) => {
  return (
    <div style={{ display: "flex", gap: "1em" }}>
      {os !== "android" && (
        <a href={config.app_download.ios} target="_blank">
          <img className="hoverX" src={alt ? alt_ios : ios} />
        </a>
      )}

      {os !== "ios" && (
        <a href={config.app_download.android} target="_blank">
          <img className="hoverX" src={alt ? alt_android : android} />
        </a>
      )}
    </div>
  );
};

export default GetApp;
