import { React, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavBar } from "../widgets";
import { Collaboration, Ecosystem, Footer, MicroLearning } from "./sections";

import { SessionExpired } from "../widgets";

import background from "../../media/website/background/shutterstock_1412893418-blur2.jpg";
import collabBackground from "../../media/website/background/smartworks-coworking-cW4lLTavU80-unsplash.jpg";

const video_placeholder = require("../../media/website/video/yongo.jpg");
const video_source = require("../../media/website/video/yongo.mp4");

const Home = ({ language, os }) => {
  ////////////// INITIALIZE //////////////
  const navigate = useNavigate();

  const [sessionExpired, setSessionExpired] = useState(false);
  const [expiredOpacity, setExpiredOpacity] = useState(0);

  const timeoutRef = useRef();
  const pageRef = useRef();
  const video_ref = useRef();

  const sections = ["app"];

  //expired session
  useEffect(() => {
    if (sessionStorage.getItem("timedout")) {
      setSessionExpired(true);
      setExpiredOpacity(1);
    }
  }, []);

  //scroll to #section or top
  useEffect(() => {
    const scrollToSection = async () => {
      try {
        let section = "";
        if (window.location.hash)
          section = window.location.hash.replace(/#/, "");
        scrollSection(section);
      } catch (err) {
        window.scrollTo(0, 0);
      }
    };
    scrollToSection();
  }, []);

  const scrollSection = (section) => {
    if (sections.includes(section)) {
      let pos = document.getElementById(section).getBoundingClientRect().top;
      window.scrollTo({
        top: pos + window.scrollY - 80,
        behavior: "smooth",
      });
    } else {
      window.scrollTo(0, 0);
    }
  };

  //scroll to get app
  const getApp = async () => {
    try {
      let pos = document.getElementById("app").getBoundingClientRect().top;
      window.scrollTo({
        top: pos + window.scrollY - 80,
        behavior: "smooth",
      });
    } catch (err) {
      console.log(err);
    }
  };

  ////////////// RENDER GUI //////////////
  return (
    <main ref={pageRef}>
      {sessionExpired && (
        <SessionExpired
          language={language}
          params={{ expiredOpacity, setExpiredOpacity, setSessionExpired }}
        />
      )}

      <NavBar language={language} buttons={true} clear={true} />

      {/**landing screen */}
      <div
        className="web-image-container"
        style={{
          backgroundImage: `url(${background})`,
          marginTop: "-80px",
          backgroundColor: "transparent",
        }}
      >
        <div className="public landing-screen">
          <div className="flex-switch-680px gapXL">
            <h2
              className="heading2 font-size-XXL font-white"
              style={{
                lineHeight: "initial",
                fontWeight: 500,
              }}
            >
              {language.website.motto}
            </h2>

            <div aria-hidden={true} ref={video_ref} className="landing-video">
              <video
                muted={true}
                autoPlay={true}
                poster={video_placeholder}
                loop={true}
                style={{
                  borderRadius: "16px",
                  boxShadow: "1px 1px 8px #000000aa",
                  border: "2px solid white",
                }}
                playsInline={true}
              >
                <source src={video_source} type="video/mp4" />
              </video>
            </div>
          </div>

          <button
            className="demo-request-button"
            onClick={() => {
              clearTimeout(timeoutRef);
              navigate("/platform");
            }}
            style={{ marginTop: "3em" }}
          >
            <div>{language.website.platform.discover}</div>
            <span className="glyphs">{">"}</span>
          </button>
        </div>
      </div>

      <Ecosystem language={language} params={{ timeoutRef, pageRef }} />

      <Collaboration
        language={language}
        params={{ timeoutRef, background: collabBackground }}
      />

      <MicroLearning language={language} params={{ getApp }} />

      {/* bottom spacing, app link and footer */}
      <div id="app" />
      <Footer language={language} params={{ os, scrollSection }} />
    </main>
  );
};

export default Home;
