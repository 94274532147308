import { React, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { TopBar, SideBar } from "../../layout";

import { getContext } from "../../../lib/signin";
import { Key, Details } from "./communitySteps";

const NewCommunity = ({
  checkPolicies,
  language,
  signout,
  pageRefs,
  pageFunctions,
}) => {
  ////////////// INITIALIZATION //////////////////
  const auth = useSelector((state) => state.authentication.value);
  const navigate = useNavigate();

  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});

  const [currentStep, setCurrentStep] = useState(0);
  const [nextStep, setNextStep] = useState([]);

  const [licenseKey, setLicenseKey] = useState("");
  const [uname, setUname] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const def_focus = useRef();

  const initialize = async (obj, id, role) => {
    try {
      setCommunities(obj);
      let _community = await getContext(id, role, obj);
      setCommunity(_community);
    } catch (err) {
      signout();
    }
  };

  useEffect(() => {
    if (
      auth.agree_terms !== "x" &&
      auth.uid &&
      localStorage.getItem("communities")
    ) {
      let obj = JSON.parse(localStorage.getItem("communities"));
      initialize(obj, auth.community_id, auth.role);
    } else {
      signout();
    }
  }, []);

  ////////////// STEPS /////////////////
  const next = (step) => {
    //check we haven't timed out first
    // checkUser();

    setNextStep(step);
    setTimeout(() => {
      setCurrentStep(step.step);
      setNextStep(null);
    }, 300);
  };
  const allSteps = [
    <Key
      checkPolicies={checkPolicies}
      language={language}
      signout={signout}
      animation={nextStep}
      next={next}
      licenseKey={licenseKey}
      setLicenseKey={setLicenseKey}
      navigate={navigate}
      def_focus={def_focus}
    />,
    <Details
      language={language}
      signout={signout}
      animation={nextStep}
      next={next}
      navigate={navigate}
      states={{ uname, title, setTitle, description, setDescription }}
    />,
  ];

  return (
    <div className="main" ref={pageRefs._main_}>
      <div
        className="sr-only"
        id="primary_focus"
        tabIndex={0}
        onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
      ></div>
      <div
        ref={pageRefs.skip_link}
        className="link sr-only"
        role="link"
        onClick={() => pageFunctions.skipLink(false)}
        onKeyUpCapture={(e) =>
          e.key === "Enter" && pageRefs.main_focus.current.focus()
        }
        onFocus={() => pageFunctions.skipLink()}
        onBlur={() => pageFunctions.skipLink(false)}
        tabIndex={0}
      >
        {language.labels.aria.skip}
      </div>
      <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
      <SideBar
        language={language}
        signout={signout}
        auth={auth}
        communities={communities.length}
        community={community}
        resetMenu={pageFunctions.resetMenu}
        ref={pageRefs.sidebar_ref}
      />
      <main className="main-page" ref={pageRefs.main_focus} tabIndex="0">
        <div
          className="page-section mobile-justify"
          style={{ display: "flex" }}
        >
          <h1 className="heading">
            {language.labels.communities.start_community}
          </h1>
        </div>
        <div>
          {allSteps[currentStep]}
          {nextStep && allSteps[nextStep.step]}
        </div>
      </main>
    </div>
  );
};

export default NewCommunity;
