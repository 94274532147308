import { React, useState, useEffect } from "react";
import background from "../../../media/website/background/heshan.jpg";

const Testimonial = ({ language, params }) => {
  ////////////// INIT //////////////
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    switch (params.user) {
      case "alfred":
        let alfred = require("../../../media/website/testimonials/alfred.jpg");
        setAvatar(alfred);
        break;
      default:
        let def = require("../../../media/website/testimonials/nasser.jpg");
        setAvatar(def);
    }
  }, []);

  ////////////// RENDER GUI //////////////
  return (
    <div
      className="web-image-container"
      style={{
        backgroundImage: `url(${background})`,
        backgroundColor: "transparent",
      }}
    >
      <div className="public websection" style={{ padding: "2em 0" }}>
        <h3
          className="heading2 font-size-L"
          style={{
            fontWeight: 500,
            marginBottom: "0.5em",
          }}
        >
          {language.website.testimonials[params.user].heading}
        </h3>

        <div
          className="chat-bubble"
          dangerouslySetInnerHTML={{
            __html: language.website.testimonials[params.user].quote,
          }}
        />

        <div
          style={{
            display: "flex",
            marginTop: "30px",
            gap: "1em",
            alignItems: "flex-start",
          }}
        >
          <div style={{ flexBasis: "90px", flexGrow: 0, flexShrink: 0 }}>
            <img
              style={{ width: "90px", borderRadius: "45px" }}
              src={avatar}
              alt={language.website.testimonials[params.user].name}
            />
          </div>

          <div style={{ flex: 1 }}>
            <div style={{ fontWeight: "500", fontSize: "1.1em" }}>
              {language.website.testimonials[params.user].name}
            </div>
            <div style={{ fontStyle: "italic", fontSize: "1.1em" }}>
              {language.website.testimonials[params.user].title}
            </div>
            <div>{language.website.testimonials[params.user].org}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
