import { React, useRef } from "react";
import { useNavigate } from "react-router-dom";
const hub = require("../../../media/website/hub.jpg");

const Hub = ({ language, params }) => {
  //////////////INITIALIZE //////////////
  const navigate = useNavigate();

  ////////////// RENDER GUI //////////////
  return (
    <div
      className={
        params.background ? "web-image-container" : "public websection"
      }
      style={
        params.background && {
          backgroundImage: `url(${params.background})`,
        }
      }
    >
      <div className={`public ${params.background && "websection"}`}>
        <div className="card-public">
          <h2
            className="heading2"
            style={{ fontSize: "2em", fontWeight: 500, marginBottom: "0.5em" }}
          >
            {language.labels.brand.hub}
          </h2>
          <div className="flex-switch-900px reverse gapL">
            {/* image */}
            <div className="media" aria-hidden={true}>
              <img
                className="phone-recording"
                src={hub}
                style={{ borderWidth: "2px" }}
              />
            </div>

            {/* blurb */}
            <div>
              <div
                style={{
                  fontSize: "1.1em",
                }}
              >
                {language.website.hub}
              </div>
              <button
                className="pill-button"
                onClick={() => {
                  navigate("/pricing");
                }}
                style={{ marginTop: "2em", fontSize: "1.2em" }}
              >
                <div style={{ fontWeight: 400 }}>
                  {language.website.start_community}
                </div>
                <span className="glyphs">{">"}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hub;
