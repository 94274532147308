import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { TopBar, SideBar } from "../../layout";

import BlockAbout from "./about";
import BlockVideos from "./videos";
import BlockKnowledge from "./knowledge";
import BlockLessons from "./lessons";
import config from "../../../config";
import axiosCall from "../../../lib/axios";
import { getContext } from "../../../lib/signin";
import Modal from "../../modal/Modal";

const BlockDetails = ({ language, signout, pageRefs, pageFunctions }) => {
  //////////// INITIALIZE ///////////
  const { state } = useLocation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authentication.value);
  const bid = state.bid;

  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});
  const [level, setLevel] = useState(-1);

  const [subtab, setSubtab] = useState("aboutTab");

  const [block, setBlock] = useState({});

  const [gotPathways, setGotPathways] = useState(false);
  const [blockPathways, setBlockPathways] = useState([]);
  const [communityPathways, setCommunityPathways] = useState([]);

  const [gotLessons, setGotLessons] = useState(false);
  const [lessonsState, setLessonsState] = useState("list");
  const [blockLessons, setBlockLessons] = useState([]);
  const [refreshLessons, setRefreshLessons] = useState(false);

  const [gotVideos, setGotVideos] = useState(false);
  const [videoState, setVideoState] = useState("list");
  const [blockVideos, setBlockVideos] = useState([]);

  const [gotKnowledge, setGotKnowledge] = useState(false);
  const [knowledgeState, setKnowledgeState] = useState("list");
  const [blockKnowledge, setBlockKnowledge] = useState([]);
  const [refreshKnowledge, setRefreshKnowledge] = useState(false);

  const [displayModal, setDisplayModal] = useState(false);

  const [updateError, setUpdateError] = useState(null);
  const [dirty, setDirty] = useState(false);

  const update_error = useRef();
  const account_ref = useRef();

  const initialize = async (obj, id, role) => {
    try {
      setCommunities(obj);
      let _community = await getContext(id, role, obj);
      setCommunity(_community);
      setLevel(config.levels.indexOf(role));

      if (!block._id) getBlock();
    } catch (err) {
      console.log(err);
      signout();
    }
  };

  /////////// abandoned because only works on page refresh or close ///////////
  // Event handler for beforeunload
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     // Most modern browsers will show a generic message, but this still ensures it works.
  //     const message = language.labels.error.leave;
  //     event.returnValue = message; // Standard for most browsers
  //     return message; // For older browsers
  //   };
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  useEffect(() => {
    if (
      auth.agree_terms !== "x" &&
      auth.uid &&
      localStorage.getItem("communities")
    ) {
      let obj = JSON.parse(localStorage.getItem("communities"));
      initialize(obj, auth.community_id, auth.role);
    } else {
      signout();
    }
  }, []);

  useEffect(() => {
    if (subtab === "kbTab" && refreshKnowledge) getKnowledge();
  }, [refreshKnowledge]);

  useEffect(() => {
    if (subtab === "lessonTab" && refreshLessons) getLessons();
  }, [refreshLessons]);

  const getBlock = async () => {
    let result = await axiosCall("block/info", { bid });
    if (result.success) {
      setBlock(result.data.block);
      if (!gotPathways) getPathways();
      if (state.referredBy) {
        if (state.referredBy === "knowledge") {
          getKnowledge();
          setKnowledgeState("new");
          setSubtab("kbTab");
        } else {
          getVideos();
          setVideoState("new");
          setSubtab("videoTab");
        }
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      getBlock();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const goBack = () => {
    if (state.pid) {
      navigate("/pathway", { state: { pid: state.pid } });
    }
    if (state.video) {
      navigate("/video", { state: { params: state.video } });
    }
    if (state.thread) {
      navigate("/thread", { state: { thread: state.thread } });
    }
  };

  //////////// SUNDRY FUNCTIONS ///////////
  const getPathways = async () => {
    let result = await axiosCall("block/pathways/list", { bid });
    if (result.success) {
      setGotPathways(true);
      setBlockPathways(result.data.block_pathways);
      setCommunityPathways(result.data.community_pathways);
    } else if (result.refresh) {
      //token has been refreshed, try again
      getPathways();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const getLessons = async (reset = false) => {
    let result = await axiosCall("block/lessons", { bid });
    if (result.success) {
      setGotLessons(true);
      setRefreshLessons(false);
      setBlockLessons(result.data);
      if (reset) setLessonsState("list");
    } else if (result.refresh) {
      //token has been refreshed, try again
      getLessons(reset);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const getVideos = async (reset = false) => {
    let result = await axiosCall("block/videos", { bid });
    if (result.success) {
      setGotVideos(true);
      setBlockVideos(result.data);
      if (reset) setVideoState("list");
    } else if (result.refresh) {
      //token has been refreshed, try again
      getVideos(reset);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const getKnowledge = async (reset = false) => {
    let result = await axiosCall("block/knowledge", { bid });
    if (result.success) {
      setGotKnowledge(true);
      setRefreshKnowledge(false);
      setBlockKnowledge(result.data);
      if (reset) setKnowledgeState("list");
    } else if (result.refresh) {
      //token has been refreshed, try again
      getKnowledge(reset);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  //////////// TAB INTERACTIONS ///////////
  function switchTab(e) {
    const tp = ["aboutTab", "videoTab", "kbTab", "lessonTab"];
    var n = tp.indexOf(e.target.id);
    if (n > -1) {
      if (e.key === "ArrowRight") n++;
      if (e.key === "ArrowLeft") n--;
      if (n > -1 && n < tp.length) {
        selectTab(tp[n]);
        document.getElementById(tp[n]).focus();
      }
    }
  }

  function selectTab(tab) {
    if (tab !== subtab) {
      if (dirty) {
        if (window.confirm(language.labels.error.unsaved)) changeTab(tab);
      } else {
        changeTab(tab);
      }
    }
  }

  function changeTab(tab) {
    setDirty(false);

    if (tab === "videoTab") {
      if (!gotVideos) getVideos(true);
    } else {
      setVideoState("list");
    }

    if (tab === "kbTab") {
      if (!gotKnowledge) getKnowledge(true);
    } else {
      setKnowledgeState("list");
    }

    if (tab === "lessonTab") {
      if (!gotLessons) getLessons(true);
    } else {
      setLessonsState("list");
    }

    setSubtab(tab);
  }

  function hideUpdateError() {
    update_error.current.style.maxHeight = "0";
    setTimeout(() => {
      setUpdateError(null);
      update_error.current.style.visibility = "hidden";
    }, 500);
  }

  //////////// RENDER GUI ///////////
  if (block._id) {
    return (
      <div className="main" ref={pageRefs._main_}>
        <div
          className="sr-only"
          id="primary_focus"
          tabIndex={0}
          onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
        ></div>
        <div
          ref={pageRefs.skip_link}
          className="link sr-only"
          role="link"
          onClick={() => pageFunctions.skipLink(false)}
          onKeyUpCapture={(e) =>
            e.key === "Enter" && pageRefs.main_focus.current.focus()
          }
          onFocus={() => pageFunctions.skipLink()}
          onBlur={() => pageFunctions.skipLink(false)}
          tabIndex={0}
        >
          {language.labels.aria.skip}
        </div>
        <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
        <SideBar
          language={language}
          signout={signout}
          auth={auth}
          communities={communities.length}
          community={community}
          resetMenu={pageFunctions.resetMenu}
          dirty={dirty}
          ref={pageRefs.sidebar_ref}
        />
        <main className="main-page" ref={pageRefs.main_focus} tabIndex={0}>
          {/* update error */}
          <div
            className="warning-banner"
            role="banner"
            ref={update_error}
            style={{ maxHeight: "0", visibility: "hidden" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5em",
                padding: "1em 1em 0.25em 1em",
                maxWidth: "44rem",
              }}
              role="alert"
            >
              <div className="glyphs alert">!</div>
              <div>{language.labels.bites.error.update}</div>
              <div style={{ flex: "1" }}></div>
              <div className="glyphs hover" onClick={() => hideUpdateError()}>
                x
              </div>
            </div>
            <div style={{ fontSize: "0.9em", padding: "0 1em 1.5em 1em" }}>
              {updateError}
            </div>
          </div>

          {/* heading */}
          <div className="page-section">
            <h1 className="heading">{language.labels.bites.bite}</h1>
          </div>

          {/* block */}
          <div className="page-section" style={{ display: "flex" }}>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                role="img"
                aria-label={block.title}
                className="menu-crest"
                style={{
                  width: "3em",
                  height: "3em",
                  backgroundImage: `url("${
                    config.server.storage.bucket + block.thumb
                  }")`,
                }}
              ></div>
              <div style={{ width: "0.5em" }}></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "calc(100% - 2.5em)",
                }}
              >
                <div
                  className="clamped one"
                  style={{
                    fontStyle: "italic",
                    marginBottom: "2px",
                    fontWeight: "300",
                  }}
                >
                  {block.topic}
                </div>
                <h2
                  className="heading"
                  style={{ width: "100%", padding: "0", fontSize: "1.2em" }}
                >
                  {block.title}
                </h2>
                <div className="handle2" style={{ width: "100%" }}>
                  {community.title}
                </div>
              </div>
            </div>
          </div>

          {/* back */}
          {(state.pid || state.video || state.thread) && (
            <div className="page-section">
              <div
                style={{ display: "flex", alignItems: "center", gap: "2px" }}
                onClick={() => goBack()}
              >
                <button
                  className="glyphs link-button"
                  aria-label={
                    state.pid
                      ? language.labels.pathways.back
                      : language.labels.videos.back
                  }
                  onClick={() => goBack()}
                  style={{ fontSize: "0.75em" }}
                >
                  &lt;
                </button>
                <div className="link" aria-hidden={true}>
                  {state.pid && language.labels.pathways.back}
                  {state.video && language.labels.videos.back}
                  {state.thread &&
                    language.labels.knowledge.back[state.thread.knowledge_type]}
                </div>
              </div>
            </div>
          )}
          <div className="page-section">
            {/* subtab menu */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1.5em",
                margin: "0.5em 0 1em 0",
              }}
              role="tablist"
            >
              <div
                role="tab"
                id="aboutTab"
                className={`subtab ${subtab !== "aboutTab" && "off"}`}
                onClick={() => selectTab("aboutTab")}
                aria-selected={subtab === "aboutTab" ? "true" : "false"}
                tabIndex={subtab === "aboutTab" ? "0" : "-1"}
                onKeyUpCapture={(e) => switchTab(e)}
              >
                <span>{language.labels.app.about}</span>
                <div></div>
              </div>

              <div
                role="tab"
                id="videoTab"
                className={`subtab ${subtab !== "videoTab" && "off"}`}
                onClick={() => selectTab("videoTab")}
                aria-selected={subtab === "videoTab" ? "true" : "false"}
                tabIndex={subtab === "videoTab" ? "0" : "-1"}
                onKeyUpCapture={(e) => switchTab(e)}
              >
                <span>{language.labels.navigation.videos}</span>
                <div></div>
              </div>

              <div
                role="tab"
                id="kbTab"
                className={`subtab ${subtab !== "kbTab" && "off"}`}
                onClick={() => selectTab("kbTab")}
                aria-selected={subtab === "kbTab" ? "true" : "false"}
                tabIndex={subtab === "kbTab" ? "0" : "-1"}
                onKeyUpCapture={(e) => switchTab(e)}
              >
                <span>{language.labels.navigation.knowledge}</span>
                <div></div>
              </div>

              {level > 0 && (
                <div
                  role="tab"
                  id="lessonTab"
                  className={`subtab ${subtab !== "lessonTab" && "off"}`}
                  onClick={() => selectTab("lessonTab")}
                  aria-selected={subtab === "lessonTab" ? "true" : "false"}
                  tabIndex={subtab === "lessonTab" ? "0" : "-1"}
                  onKeyUpCapture={(e) => switchTab(e)}
                >
                  <span>{language.labels.navigation.lessons}</span>
                  <div></div>
                </div>
              )}
            </div>

            {/* about tab page */}
            {subtab === "aboutTab" && (
              <BlockAbout
                readOnly={block.community !== community.id || level < 2}
                language={language}
                block={block}
                setBlock={setBlock}
                setUpdateError={setUpdateError}
                gotPathways={gotPathways}
                blockPathways={blockPathways}
                getPathways={getPathways}
                communityPathways={communityPathways}
                main_focus={pageRefs.main_focus}
                update_error={update_error}
                signout={signout}
              ></BlockAbout>
            )}

            {/* video tab page */}
            {subtab === "videoTab" && (
              <BlockVideos
                level={level}
                community={community}
                language={language}
                bid={bid}
                blockVideos={blockVideos}
                videoState={videoState}
                setVideoState={setVideoState}
                getVideos={getVideos}
                account={auth.account_type}
                setDisplayModal={setDisplayModal}
                setDirty={setDirty}
                signout={signout}
              ></BlockVideos>
            )}

            {/* kb tab page */}
            {subtab === "kbTab" && (
              <BlockKnowledge
                level={level}
                community={community}
                language={language}
                bid={bid}
                blockKnowledge={blockKnowledge}
                knowledgeState={knowledgeState}
                setKnowledgeState={setKnowledgeState}
                getKnowledge={getKnowledge}
                setRefreshKnowledge={setRefreshKnowledge}
                account={auth.account_type}
                setDisplayModal={setDisplayModal}
                setDirty={setDirty}
                signout={signout}
              ></BlockKnowledge>
            )}

            {/* lesson tab page */}
            {subtab === "lessonTab" && (
              <BlockLessons
                community={community}
                language={language}
                bid={bid}
                blockLessons={blockLessons}
                lessonsState={lessonsState}
                setLessonsState={setLessonsState}
                getLessons={getLessons}
                setRefreshLessons={setRefreshLessons}
                setDirty={setDirty}
                signout={signout}
              ></BlockLessons>
            )}
          </div>
        </main>

        <Modal
          ref={account_ref}
          className="modal-dynamic"
          title={language.labels.account.upgrade}
          show={displayModal}
          language={language}
        >
          <section
            className="focus-modest"
            id="modal_body"
            aria-label={language.labels.account.upgrade}
            tabIndex={0}
            style={{ padding: "1em 0" }}
          >
            {language.labels.account.free_tier}
          </section>
          <div style={{ display: "flex", padding: "0.5em 0" }}>
            <button
              className="button-on"
              onClick={() => setDisplayModal(false)}
            >
              {language.labels.app.ok}
            </button>
          </div>
        </Modal>
      </div>
    );
  } else {
    return <></>;
  }
};

export default BlockDetails;
