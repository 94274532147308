import { React, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { GetApp } from "../../widgets";

const app = require("../../../media/website/app.jpg");

const App = ({ language, params }) => {
  //////////////INITIALIZE //////////////
  const navigate = useNavigate();

  const few_features = [
    "collab_community",
    "live_assistance",
    "chat",
    "expert_content",
    "video_feed",
    "immersion",
    "collab_learning",
    "ai_learning",
    "pathways",
    "live_learning",
  ];

  const some_features = [
    "collab_community",
    "live_assistance",
    "chat",
    "directory",
    "expert_content",
    "video_feed",
    "immersion",
    "profile",
    "collab_learning",
    "ai_learning",
    "pathways",
    "live_learning",
  ];

  ////////////// RENDER GUI //////////////
  return (
    <div
      className={
        params.background ? "web-image-container" : "public websection"
      }
      style={
        params.background
          ? {
              backgroundImage: `url(${params.background})`,
            }
          : params.personal && { padding: "4em 0" }
      }
    >
      <div className={`public ${params.background && "websection"}`}>
        <div className="card-public">
          <h2
            className="heading2"
            style={{
              fontSize: params.os ? "1.4em" : "2em",
              fontWeight: 500,
              marginBottom: "1em",
              lineHeight: "initial",
            }}
          >
            {params.os
              ? language.labels.brand.available[params.os]
              : language.website.platform.app}
          </h2>

          {params.os && (
            <div style={{ padding: "1em 0 2em 0" }}>
              <GetApp os={params.os} language={language} alt={true} />
            </div>
          )}

          <div className="flex-switch-900px reverse gapL">
            {/* blurb */}
            <div style={{ marginTop: "-2em" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${
                    params.personal
                      ? language.website.app_personal
                      : language.website.app
                  }`,
                }}
                style={{
                  fontSize: "1.1em",
                }}
              />

              <div style={{ fontSize: "1.1em", fontWeight: "500" }}>
                {params.os
                  ? language.website.features.some
                  : language.website.features.few}
              </div>

              {params.os
                ? some_features.map((feature, index) => (
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        gap: "0.5em",
                      }}
                    >
                      <div className="successtext">✓</div>
                      <div>{language.website.features[feature]}</div>
                    </div>
                  ))
                : few_features.map((feature, index) => (
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        gap: "0.5em",
                      }}
                    >
                      <div className="successtext">✓</div>
                      <div>{language.website.features[feature]}</div>
                    </div>
                  ))}
            </div>

            {/* image */}
            <div
              className="media"
              aria-hidden={true}
              style={{ position: "relative" }}
            >
              <img className="phone-recording" src={app} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
