import { React, useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";

import { Footer, Solution, Testimonial } from "./sections";
import { NavBar } from "../widgets";
import background from "../../media/website/background/pexels-pavel-danilyuk-8423875-blur.jpg";
import bgTraining from "../../media/website/background/pexels-mikhail-nilov-9242839-blur.jpg";
import healthcare from "../../media/website/images/health.jpg";
import healthcare_thumb from "../../media/website/images/health_thumb.jpg";
import training from "../../media/website/images/training.jpg";
import training_thumb from "../../media/website/images/training_thumb.jpg";
import education from "../../media/website/images/education.jpg";
import education_thumb from "../../media/website/images/education_thumb.jpg";

import { DotButton, useDotButton } from "../../lib/EmblaCarousel";

const Solutions = ({ language, os }) => {
  //init
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false });
  const sections = ["healthcare", "training", "education"];

  //scroll to #section or top
  useEffect(() => {
    const scrollToSection = async () => {
      try {
        let section = "";
        if (window.location.hash)
          section = window.location.hash.replace(/#/, "");
        scrollSection(section);
      } catch (err) {
        window.scrollTo(0, 0);
      }
    };
    scrollToSection();
  }, []);

  const scrollSection = (section) => {
    if (sections.includes(section)) {
      let pos = document.getElementById(section).getBoundingClientRect().top;
      window.scrollTo({
        top: pos + window.scrollY - 80,
        behavior: "smooth",
      });
    } else {
      window.scrollTo(0, 0);
    }
  };

  //carousel
  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  return (
    <main>
      <NavBar language={language} buttons={true} clear={true} />
      {/* landing */}
      <div
        className="web-image-container"
        style={{
          backgroundImage: `url(${background})`,
          marginTop: "-80px",
        }}
      >
        <div className="public landing-screen">
          <div className="font-white card-heading">
            <h2 className="heading2 font-size-L" style={{ fontWeight: 500 }}>
              {language.website.solutions.headline}
            </h2>
            <div
              style={{
                marginTop: "2em",
                fontSize: "1.1em",
              }}
            >
              {language.website.solutions.featured}
            </div>
          </div>

          {/* mobile only */}
          <div className="px680-hide">
            <div style={{ padding: "1em 0" }}>
              <div className="embla" ref={emblaRef}>
                <div
                  className="embla__container"
                  style={{
                    gap: "8px",
                  }}
                >
                  {sections.map((section, index) => (
                    <div className="embla__slide">
                      <div
                        className="card-public tight px380-center"
                        style={{ height: "100%" }}
                        onClick={() => {
                          scrollSection(section);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                          }}
                        >
                          <div
                            className="font-size-R clamped one"
                            style={{
                              fontWeight: "400",
                              lineHeight: "initial",
                            }}
                          >
                            {language.website.solutions[section].heading}
                          </div>
                          <div style={{ marginTop: "1em" }}>
                            <img
                              style={{ width: "100%", maxWidth: "380px" }}
                              src={
                                section === "healthcare"
                                  ? healthcare_thumb
                                  : section === "training"
                                  ? training_thumb
                                  : education_thumb
                              }
                            />
                          </div>
                          <div style={{ margin: "1em 0" }}>
                            {language.website.solutions[section].brief}
                          </div>
                        </div>

                        <button
                          className="pill-button font-yongo px380-center"
                          onClick={() => {
                            scrollSection(section);
                          }}
                          style={{
                            marginTop: "-1em",
                            marginBottom: "1em",
                          }}
                        >
                          <span style={{ fontWeight: 400 }}>
                            {language.website.more}
                          </span>
                          <span className="glyphs">{">"}</span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                gap: "0.25rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {scrollSnaps.map((_, index) => (
                <DotButton
                  key={index}
                  onClick={() => onDotButtonClick(index)}
                  className={"embla__dot".concat(
                    index === selectedIndex ? " selected" : ""
                  )}
                />
              ))}
            </div>
          </div>

          {/* desktop only */}
          <div className="px680-show">
            <div
              style={{
                display: "flex",
                margin: "1em 0",
                alignItems: "stretch",
                gap: "1em",
              }}
            >
              {sections.map((section, index) => (
                <div
                  className="card-public tight websection hover"
                  style={{
                    flex: "1",
                  }}
                  onClick={() => {
                    scrollSection(section);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <div
                      className="font-size-R clamped one"
                      style={{
                        fontWeight: "400",
                        lineHeight: "initial",
                      }}
                    >
                      {language.website.solutions[section].heading}
                    </div>
                    <div style={{ marginTop: "1em" }}>
                      <img
                        style={{ width: "100%" }}
                        src={
                          section === "healthcare"
                            ? healthcare_thumb
                            : section === "training"
                            ? training_thumb
                            : education_thumb
                        }
                      />
                    </div>

                    <div style={{ margin: "1em 0", alignSelf: "flex-end" }}>
                      {language.website.solutions[section].brief}
                    </div>
                  </div>
                  <button
                    className="pill-button font-yongo"
                    onClick={() => {
                      scrollSection(section);
                    }}
                    style={{ marginTop: "-1em" }}
                  >
                    <div style={{ fontWeight: 400 }}>
                      {language.website.more}
                    </div>
                    <span className="glyphs">{">"}</span>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* sections */}

      <div id="healthcare" />
      <Solution
        language={language}
        params={{
          page: "healthcare",
          thumb: healthcare_thumb,
          image: healthcare,
          benefits: [
            "patient_education",
            "self_service",
            "patient_connections",
            "early_mitigation",
            "pps",
          ],
        }}
      />

      <div id="training" />
      <Solution
        language={language}
        params={{
          background: bgTraining,
          image: training,
          page: "training",
          benefits: [
            "pathways",
            "expert",
            "flexible",
            "skills_based",
            "future_proof",
          ],
        }}
      />
      <Testimonial language={language} params={{ user: "alfred" }} />

      <div id="education" />
      <Solution
        language={language}
        params={{
          page: "education",
          thumb: education_thumb,
          image: education,
          benefits: [
            "plan",
            "time",
            "personalized_learning",
            "collab",
            "create",
          ],
        }}
      />

      {/* footer */}
      <Footer language={language} params={{ os, scrollSection }} />
    </main>
  );
};

export default Solutions;
