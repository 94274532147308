import { React, useRef } from "react";
import { useNavigate } from "react-router-dom";
const app = require("../../../media/website/app.jpg");

const MicroLearning = ({ language, params }) => {
  //////////////INITIALIZE //////////////
  const navigate = useNavigate();

  ////////////// RENDER GUI //////////////
  return (
    <div
      className={
        params.background ? "web-image-container" : "public websection"
      }
      style={
        params.background && {
          backgroundImage: `url(${params.background})`,
        }
      }
    >
      <div className={`public ${params.background && "websection"}`}>
        <div className="card-public flex-switch-680px reverse gapL">
          {/* blurb */}
          <div>
            <h2
              className="heading2"
              style={{
                fontSize: "2em",
                fontWeight: 500,
                marginBottom: "0.5em",
                lineHeight: "initial",
              }}
            >
              {language.website.micro_learning.heading}
            </h2>
            <div>{language.website.micro_learning.body}</div>

            {params?.getApp && (
              <button
                className="pill-button"
                onClick={params.getApp}
                style={{ marginTop: "2em", fontSize: "1.2em" }}
              >
                <div style={{ fontWeight: 400 }}>
                  {language.website.get_app}
                </div>
                <span className="glyphs">{">"}</span>
              </button>
            )}
          </div>

          {/* image */}
          <div
            className="media"
            aria-hidden={true}
            style={{ position: "relative" }}
          >
            <img className="phone-recording" src={app} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MicroLearning;
