import { React, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import expert from "../../../media/website/user/expert.jpg";
import andrew from "../../../media/website/user/user1.jpg";
import melissa from "../../../media/website/user/user2.jpg";
import ai from "../../../media/website/user/ai.png";

const Ecosystem = ({ language, params }) => {
  //////////////INITIALIZE //////////////
  const navigate = useNavigate();

  const [typedMessages] = useState([
    {
      question: language.website.chat.ecosystem_1.question,
      answer: language.website.chat.ecosystem_1.answer,
      responder: language.website.chat.ecosystem_1.responder,
      responderAvatar: expert,
    },
    {
      question: language.website.chat.ecosystem_2.question,
      answer: language.website.chat.ecosystem_2.answer,
      responder: language.website.chat.ecosystem_2.responder,
      responderAvatar: ai,
    },
    {
      question: language.website.chat.ecosystem_3.question,
      answer: language.website.chat.ecosystem_3.answer,
      responder: language.website.chat.ecosystem_3.responder,
      responderAvatar: andrew,
    },
    {
      question: language.website.chat.ecosystem_4.question,
      answer: language.website.chat.ecosystem_4.answer,
      responder: language.website.chat.ecosystem_4.responder,
      responderAvatar: melissa,
    },
  ]);

  const currentMessageIndex = useRef(0);
  const typedBodyRef = useRef();
  const iconRef = useRef();

  const [chatQuestion, setChatQuestion] = useState("");
  const [chatResponder, setChatResponder] = useState("");
  const [chatAvatar, setChatAvatar] = useState("expert");
  const [chatAnswer, setChatAnswer] = useState("");

  useEffect(() => {
    clearTimeout(params.timeoutRef.current);
    startTyping();
  }, []);

  ////////////// FUNCTIONS //////////////
  const startTyping = (part = "question", n = 1) => {
    if (!params.pageRef.current) {
      clearTimeout(params.timeoutRef);
      return;
    }

    const message = typedMessages[currentMessageIndex.current][part];
    let typedText = "";
    let textEnd = false;
    n++;

    if (n <= message.length) {
      typedText = message.substring(0, n);
    } else {
      textEnd = true;
    }

    switch (part) {
      case "responder":
        setChatAvatar(
          typedMessages[currentMessageIndex.current].responderAvatar
        );
        iconRef.current.style.display = "flex";
        params.timeoutRef.current = setTimeout(
          () => startTyping("responder", n),
          40
        );
        if (textEnd) {
          clearTimeout(params.timeoutRef.current);
          startTyping("answer");
        } else {
          setChatResponder(typedText);
        }
        break;
      case "answer":
        params.timeoutRef.current = setTimeout(
          () => startTyping("answer", n),
          40
        );
        if (textEnd) {
          clearTimeout(params.timeoutRef.current);
          loopTyping();
        } else {
          setChatAnswer(typedText);
        }
        break;
      default:
        iconRef.current.style.display = "none";
        setChatResponder(null);
        setChatAnswer(null);
        params.timeoutRef.current = setTimeout(
          () => startTyping("question", n),
          40
        );
        if (textEnd) {
          clearTimeout(params.timeoutRef.current);
          startTyping("responder");
        } else {
          setChatQuestion(typedText);
        }
        break;
    }
  };

  const loopTyping = () => {
    params.timeoutRef.current = setTimeout(() => {
      typedBodyRef.current && (typedBodyRef.current.style.opacity = 0);
      iconRef.current && (iconRef.current.style.opacity = 0);
      setTimeout(() => {
        iconRef.current && (iconRef.current.style.display = "none");
        typedBodyRef.current && (typedBodyRef.current.style.opacity = 1);
        iconRef.current && (iconRef.current.style.opacity = 1);
        currentMessageIndex.current = currentMessageIndex.current + 1;
        if (currentMessageIndex.current >= typedMessages.length) {
          currentMessageIndex.current = 0;
        }
        startTyping();
      }, 350);
    }, 5000);
  };

  ////////////// RENDER GUI //////////////
  return (
    <div
      className={
        params.background ? "web-image-container" : "public websection"
      }
      style={
        params.background && {
          backgroundImage: `url(${params.background})`,
        }
      }
    >
      <div className={`public ${params.background && "websection"}`}>
        <div className="flex-switch-680px">
          {/* blurb */}
          <div className="card-public">
            <h2
              className="heading2"
              style={{
                fontSize: "2em",
                fontWeight: 500,
                marginBottom: "0.5em",
              }}
            >
              {language.website.ecosystem.heading}
            </h2>
            <div
              style={{
                fontSize: "1.1em",
              }}
            >
              {language.website.ecosystem.body}
            </div>
            <button
              className="pill-button"
              onClick={() => {
                clearTimeout(params.timeoutRef);
                navigate("/contact");
              }}
              style={{ marginTop: "2em", fontSize: "1.2em" }}
            >
              <div style={{ fontWeight: 400 }}>
                {language.website.demo.request}
              </div>
              <span className="glyphs">{">"}</span>
            </button>
          </div>

          {/* chat box */}
          <div className="chat-bubble alt">
            <div
              style={{
                fontSize: "1.2em",
                fontWeight: 500,
                marginTop: "unset",
                lineHeight: "initial",
              }}
            >
              {chatQuestion}
            </div>
            <div ref={iconRef} className="type-writer-responder">
              <img
                src={chatAvatar}
                style={{
                  height: 32,
                  flexBasis: 32,
                  borderRadius: 40,
                  aspectRatio: 1,
                  flexGrow: 0,
                  flexShrink: 1,
                }}
              />
              <div style={{ fontWeight: 500 }}>{chatResponder}</div>
            </div>
            <div ref={typedBodyRef} className="type-writer-answer">
              {chatAnswer}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ecosystem;
