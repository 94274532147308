import { React, useState, useRef } from "react";
import axios from "axios";

import config from "../../../config";
import validator from "../../../lib/validation";

const ContactForm = ({ language, params }) => {
  ////////////// INITIALIZE //////////////
  const name_ref = useRef();
  const email_ref = useRef();
  const org_ref = useRef();
  const other_ref = useRef();
  const submit_ref = useRef();

  const [formError, setFormError] = useState(null);
  const [submitError, setSubmitError] = useState(false);
  const [success, setSuccess] = useState(null);

  ////////////// FORM SUBMISSION //////////////
  const onEnterKey = () => {
    if (document.activeElement === name_ref.current) {
      email_ref.current.focus();
    } else if (document.activeElement === email_ref.current) {
      org_ref.current.focus();
    } else if (document.activeElement === org_ref.current) {
      other_ref.current.focus();
    } else if (document.activeElement === other_ref.current) {
      submit_ref.current.focus();
    }
  };

  const checkForm = () => {
    setSubmitError(false);
    setFormError(null);
    if (name_ref.current.value.trim() === "") {
      setFormError("name");
      name_ref.current.focus();
    } else if (
      email_ref.current.value.trim() === "" ||
      !validator.emailValidator(email_ref.current.value.trim())
    ) {
      setFormError("email");
      email_ref.current.focus();
    } else if (org_ref.current.value.trim() === "") {
      setFormError("org");
      org_ref.current.focus();
    } else {
      sendRequest();
    }
  };

  const sendRequest = async () => {
    document.getElementById("loader").style.display = "inline";

    try {
      const configurationObject = {
        url: `${config.server.api}/public/contact`,
        method: "POST",
        data: {
          name: name_ref.current.value,
          org: org_ref.current.value,
          other: other_ref.current.value,
          email: email_ref.current.value,
          lang: language.lang,
        },
        withCredentials: true,
        credentials: "include",
      };

      const response = await axios(configurationObject);
      document.getElementById("loader").style.display = "none";

      if (response.status === 200) {
        //submit
        setSuccess(
          language.website.demo.success.replace(
            /{email}/g,
            email_ref.current.value
          )
        );
      } else {
        setSubmitError(true);
      }
    } catch (err) {
      document.getElementById("loader").style.display = "none";
      setSubmitError(true);
    }
  };

  ////////////// RENDER GUI //////////////
  return (
    <div
      className={
        params.background ? "web-image-container" : "public websection"
      }
      style={
        params.background && {
          backgroundImage: `url(${params.background})`,
          marginTop: "-80px",
        }
      }
    >
      <div className={params.background ? "public landing-screen" : ""}>
        <div className="flex-switch-900px" style={{ gap: "1em" }}>
          {/* blurb */}
          <div className="flex-switch-900px even">
            <div>
              <h2
                className={`heading2 font-size-XL ${
                  params.background ? "font-white" : "public-text-container"
                }`}
                style={{
                  fontWeight: 500,
                }}
              >
                {language.website.demo.action}
              </h2>
              <div
                className={`public ${
                  params.background ? "font-white" : "public-text-container"
                }`}
                style={{
                  fontSize: "1.1em",
                  marginTop: "1em",
                  lineHeight: "1.8em",
                }}
              >
                {language.website.demo.blurb}
              </div>
            </div>
          </div>

          <div
            className="card-public flex-switch-900px even"
            style={params.background ? { margin: 0, boxShadow: `none` } : {}}
          >
            {success ? (
              //successfully submitted form
              <div>
                <div style={{ fontWeight: 500, fontSize: "1.2em" }}>
                  {language.website.thank_you}
                </div>
                <div
                  style={{ lineHeight: "1.8em" }}
                  dangerouslySetInnerHTML={{
                    __html: success,
                  }}
                />
              </div>
            ) : (
              // submission form
              <div>
                <h3 className="heading2 text-label">
                  {language.website.demo.details}
                </h3>
                <input
                  ref={name_ref}
                  style={{ marginTop: "0.5em", width: "100%" }}
                  className={`login-input ${formError === "name" && "error"}`}
                  placeholder={language.website.demo.name}
                  aria-label={language.website.demo.name}
                  onKeyUpCapture={(e) => e.key === "Enter" && onEnterKey()}
                />
                <input
                  ref={email_ref}
                  style={{ marginTop: "1em", width: "100%" }}
                  className={`login-input ${formError === "email" && "error"}`}
                  placeholder={language.website.demo.email}
                  aria-label={language.website.demo.email}
                  onKeyUpCapture={(e) => e.key === "Enter" && onEnterKey()}
                />
                {formError === "email" && (
                  <div className="errtext">
                    {language.labels.authentication.email_prompt}
                  </div>
                )}

                <h3
                  className="heading2 text-label"
                  style={{ marginTop: "2em" }}
                >
                  {language.website.demo.org}
                </h3>
                <input
                  ref={org_ref}
                  style={{ marginTop: "0.5em", width: "100%" }}
                  className={`login-input ${formError === "org" && "error"}`}
                  placeholder={language.website.demo.org_name}
                  aria-label={language.website.demo.org_name}
                  onKeyUpCapture={(e) => e.key === "Enter" && onEnterKey()}
                />
                <input
                  ref={other_ref}
                  style={{ marginTop: "1em", width: "100%" }}
                  className="login-input"
                  placeholder={language.website.demo.other}
                  aria-label={language.website.demo.other}
                  onKeyUpCapture={(e) => e.key === "Enter" && onEnterKey()}
                />

                {submitError && (
                  <div className="errtext" style={{ margin: "1.5em 0 -1em 0" }}>
                    {language.labels.error.unknown_later}
                  </div>
                )}

                <button
                  ref={submit_ref}
                  className="button"
                  style={{
                    width: "100%",
                    marginTop: "2.5rem",
                  }}
                  onClick={checkForm}
                >
                  {language.website.demo.submit}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
