import { React, useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { login } from "../.././../features/authentication";
import { localSignIn } from "../../../lib/signin";

import config from "../../../config";

const CodePage = ({ language, navigate, def_focus, uid, cid, target }) => {
  ////////////////// INITIALIZE //////////////////
  const dispatch = useDispatch();
  const reset_ref = useRef();

  const [code, setCode] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [codeResent, setCodeResent] = useState(false);

  useEffect(() => {
    def_focus.current.focus();
  }, []);

  ////////////////// SUBMIT CODE //////////////////
  const Submit = async (e) => {
    //check inputs
    e.preventDefault();
    if (code) {
      if (code.trim().length !== 6) {
        setErrMsg(language.labels.authentication.code_input_error);
        return;
      }
    } else {
      return;
    }

    try {
      //sign in 2fa
      localStorage.clear();
      let data = { uid, cid, code: code.trim() };
      const configurationObject = {
        url: `${config.server.api}/v2/auth/2fa/hub`,
        method: "POST",
        data,
        withCredentials: true,
        credentials: "include",
      };
      document.getElementById("loader").style.display = "inline";
      const response = await axios(configurationObject);
      document.getElementById("loader").style.display = "none";
      if (response.status === 200) {
        const signin = await localSignIn(response.data);
        if (signin) authenticate(response.data);
      } else if (response.data.error) {
        switch (response.data.error) {
          case "INACTIVE":
            setErrMsg(language.labels.error.account_inactive);
            break;
          case "CODE":
            setErrMsg(language.labels.authentication.code_input_error);
            break;
          default:
            setErrMsg(language.labels.error.signin_credentials);
        }
      } else {
        setErrMsg(language.labels.error.unknown);
      }
    } catch (error) {
      document.getElementById("loader").style.display = "none";
      setErrMsg(language.labels.error.unknown);
    }
  };

  const resendCode = async (e) => {
    try {
      let data = { uid, cid, resend: true, language: language.lang };
      if (target.email) data["email"] = target.email;
      if (target.phone) data["phone"] = target.phone;

      setErrMsg("");
      const configurationObject = {
        url: `${config.server.api}/v2/auth/sendcode/hub`,
        method: "POST",
        data,
      };
      document.getElementById("loader").style.display = "inline";
      const response = await axios(configurationObject);
      document.getElementById("loader").style.display = "none";
      if (response.status === 200) {
        reset_ref.current.style.maxHeight = "100px";
        setCodeResent(true);
        setTimeout(() => {
          reset_ref.current.style.maxHeight = "0";
          setCodeResent(false);
        }, 3000);
      } else {
        switch (response.data.message) {
          case "SMS":
            setErrMsg(language.labels.error.signin_sms);
            break;
          case "MAIL":
            setErrMsg(language.labels.error.signin_mail);
            break;
          default:
            setErrMsg(language.labels.error.signin_expired);
        }
      }
    } catch (error) {
      document.getElementById("loader").style.display = "none";
      setErrMsg(language.labels.error.unknown);
    }
  };

  ////////////////// LOG IN //////////////////
  const authenticate = (obj) => {
    let loginState = {
      client: obj.client,
      agree_terms: obj.agree_terms,
      account_type: obj.basic.account_type,
      uid: obj.basic._id,
      username: obj.basic.username,
    };
    if (obj.community_id) loginState.community_id = obj.community_id;
    if (obj.role) loginState.role = obj.role;
    if (obj.admin) {
      loginState.admin = "Y";
    } else {
      loginState.admin = "";
    }
    dispatch(login(loginState));
    navigate("/hub");
  };

  ////////////////// RENDER GUI //////////////////
  return (
    <>
      <form
        className="login-form signup"
        id="code"
        onSubmit={(e) => Submit(e)}
        aria-label={language.labels.aria.form.signup}
      >
        <div style={{ textAlign: "center", fontSize: "1.2em" }}>
          {language.labels.authentication.verify_identity}
        </div>

        <div style={{ textAlign: "center", marginTop: "1.2em" }}>
          {language.labels.authentication.code_sent.replace(
            /{target}/g,
            target.mask
          )}
        </div>

        <input
          ref={def_focus}
          style={{ marginTop: "1.2rem", width: "100%" }}
          placeholder={language.labels.authentication.code}
          aria-label={language.labels.authentication.code}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          tabIndex={0}
        />

        {errMsg && (
          <div
            style={{ fontWeight: 600, marginTop: "1rem" }}
            className="errtext"
            role="alert"
          >
            {errMsg}
          </div>
        )}

        <div
          ref={reset_ref}
          className="trans500"
          style={{ marginTop: "1rem", maxHeight: "0" }}
        >
          {codeResent && (
            <div
              style={{ fontWeight: 600, marginBottom: "0.5rem" }}
              className="subtext successtext"
              role="alert"
            >
              {language.labels.authentication.code_reset_successful}
            </div>
          )}
        </div>

        <button
          className="button"
          type="submit"
          style={{ width: "100%", marginTop: "2rem", fontSize: "1.1rem" }}
        >
          {language.labels.app.action_submit}
        </button>

        <div className="join-container">
          <div
            role="link"
            className="link"
            style={{ fontSize: "0.8em", marginTop: "1em" }}
            onClick={() => resendCode()}
            onKeyUpCapture={(e) =>
              (e.key === "Enter" || e.key === " ") && resendCode()
            }
            tabIndex="0"
          >
            {language.labels.authentication.code_reset}
          </div>
        </div>
      </form>
    </>
  );
};

export default CodePage;
