import { React } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../../config";

const PlanExpert = ({ language, params }) => {
  const navigate = useNavigate();

  const feature_list = ["ai_curriculum", "ai_lesson", "ai_knowledge"];

  const features = feature_list.map((feature, index) => (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        gap: "0.5em",
      }}
    >
      <div className="successtext">✓</div>
      <div>{language.website.features[feature]}</div>
    </div>
  ));

  return (
    <>
      <h3
        className={`${
          params.mobile && "font-white"
        } heading2 font-size-L card-heading`}
      >
        {language.website.pricing.expert.heading}
      </h3>
      <div className="flex-switch-680px">
        {/* details */}
        <div
          className="card-public"
          style={params.mobile ? { flex: 1, margin: "0" } : { flex: 1 }}
        >
          <div style={{ fontWeight: "500" }}>{config.pricing.expert}</div>
          <div>{language.website.pricing.expert.brief}</div>
          <div>{language.website.pricing.expert.details}</div>
          <div>{language.website.pricing.expert.not_included}</div>
          <div>{language.website.pricing.expert.ai_included}</div>
          {features}

          <div style={{ fontSize: "0.8em", paddingTop: "1.5em" }}>
            {language.website.pricing.per_month}
          </div>

          <button
            className="pill-button"
            onClick={() => {
              clearTimeout(params.timeoutRef);
              navigate("/contact");
            }}
            style={{ marginTop: "2em", fontSize: "1.1em" }}
          >
            <div style={{ fontWeight: 400 }}>
              {language.website.contact_sales}
            </div>
            <span className="glyphs">{">"}</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default PlanExpert;
