import { React } from "react";
import { useNavigate } from "react-router-dom";

import config from "../../../config";

const PlanPublic = ({ language, params }) => {
  const navigate = useNavigate();

  const feature_list = [
    "collab_community",
    "live_assistance",
    "chat",
    "directory",
    "expert_content",
    "video_feed",
    "immersion",
    "profile",
    "collab_learning",
    "ai_learning",
    "pathways",
    "live_learning",
  ];

  const features = feature_list.map((feature, index) => (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        gap: "0.5em",
      }}
    >
      <div className="successtext">✓</div>
      <div>{language.website.features[feature]}</div>
    </div>
  ));

  return (
    <>
      <h3
        className={`${
          params.mobile && "font-white"
        } heading2 font-size-L card-heading`}
      >
        {language.website.pricing.public.heading}
      </h3>
      <div className="flex-switch-680px">
        {/* details */}
        <div
          className="card-public"
          style={params.mobile ? { flex: 1, margin: "0" } : { flex: 1 }}
        >
          <div style={{ fontWeight: "500" }}>
            {language.website.pricing.public.price}
          </div>
          <div>{language.website.pricing.public.brief}</div>
          <div>
            {language.website.pricing.public.expert.replace(
              /{price}/g,
              config.pricing.public_expert
            )}
          </div>
          <div>
            {language.website.pricing.public.member.replace(
              /{price}/g,
              config.pricing.public_member
            )}
          </div>
          <div>{language.website.pricing.public.monetize}</div>

          <div style={{ fontSize: "0.8em", paddingTop: "1.5em" }}>
            {language.website.pricing.per_month}
          </div>

          <button
            className="pill-button"
            onClick={() => {
              clearTimeout(params.timeoutRef);
              navigate("/contact");
            }}
            style={{ marginTop: "2em", fontSize: "1.1em" }}
          >
            <div style={{ fontWeight: 400 }}>
              {language.website.contact_sales}
            </div>
            <span className="glyphs">{">"}</span>
          </button>

          {params.mobile && (
            <div style={{ marginTop: "2em" }}>
              <div style={{ fontSize: "1.1em", fontWeight: "500" }}>
                {language.website.pricing.public.features}
              </div>
              {features}
            </div>
          )}
        </div>

        {/* features */}
        {!params.mobile && (
          <div className="card-public" style={{ flex: 0.8 }}>
            <div style={{ fontSize: "1.1em", fontWeight: "500" }}>
              {language.website.pricing.public.features}
            </div>
            {features}
          </div>
        )}
      </div>
    </>
  );
};

export default PlanPublic;
