import { React, useState, useRef } from "react";
import Cookies from "universal-cookie";
import axios from "axios";

import config from "../../../config";
import {
  UsernameForm,
  CodeForm,
  PhoneForm,
  SuccessPage,
} from "./Register_Steps";

import { NavBar } from "../../widgets";
import Footer from "../../layout/Footer";

const Signup = ({ language, checkPolicies }) => {
  const cookies = new Cookies();
  const [currentStep, setCurrentStep] = useState(0);
  const [nextStep, setNextStep] = useState(null);
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [messageResponse, setMessageResponse] = useState({});

  const def_focus = useRef();

  const next = (obj) => {
    //check starting over
    if (obj.direction === -1 && obj.step === 0) {
      restart();
    }

    //transition step
    setNextStep(obj);
    setTimeout(() => {
      setCurrentStep(obj.step);
      setNextStep(null);
    }, 300);
  };

  const restart = () => {
    document.getElementById("loader").style.display = "inline";
    let configurationObject = {
      url: `${config.server.api}/auth/reset`,
      method: "POST",
      data: { uuid: cookies.get("uuid") },
    };
    axios(configurationObject)
      .then((response) => {
        document.getElementById("loader").style.display = "none";
      })
      .catch((error) => {
        document.getElementById("loader").style.display = "none";
        console.error(`LOGGED ERROR: \n`, error);
      });
  };

  const allSteps = [
    <UsernameForm
      language={language}
      animation={nextStep}
      next={next}
      def_focus={def_focus}
      cookies={cookies}
      username={username}
      setUsername={setUsername}
    />,
    <PhoneForm
      language={language}
      animation={nextStep}
      next={next}
      def_focus={def_focus}
      cookies={cookies}
      username={username}
      setPhoneNumber={setPhoneNumber}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
      setMessageResponse={setMessageResponse}
    />,
    <CodeForm
      language={language}
      animation={nextStep}
      next={next}
      def_focus={def_focus}
      cookies={cookies}
      username={username}
      phoneNumber={phoneNumber}
      email={email}
      password={password}
      messageResponse={messageResponse}
      setMessageResponse={setMessageResponse}
    />,
    <SuccessPage
      language={language}
      animation={nextStep}
      def_focus={def_focus}
      params={{
        username,
        password,
      }}
    />,
  ];

  return (
    <>
      <NavBar language={language} buttons={true} />
      <main className="public auth" style={{ padding: "2em 0" }}>
        <div className="card-public">
          <h1
            className="font-yongo login-heading"
            style={{
              textAlign: "center",
              marginBottom: "0.5em",
            }}
          >
            {currentStep < 3
              ? language.labels.authentication.signup.join.toUpperCase()
              : language.labels.app.success}
          </h1>
          <div className="login-form" style={{ marginTop: "1rem" }}>
            {allSteps[currentStep]}
            {nextStep && allSteps[nextStep.step]}
          </div>
          <Footer
            language={language}
            checkPolicies={checkPolicies}
            ref={def_focus}
          />
        </div>
      </main>
    </>
  );
};

export default Signup;
