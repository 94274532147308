import { React, useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Initiate, CodePage, SelectPage, SuccessPage } from "./Forgot_Steps";

import { NavBar } from "../../widgets";

const Forgot = ({ language }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [page, setPage] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [userName, setUserName] = useState("");
  const [registration, setRegistration] = useState("");
  const [registrations, setRegistrations] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [nextStep, setNextStep] = useState(null);
  const [messageResponse, setMessageResponse] = useState({});
  const def_focus = useRef();

  const returnToLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    if (!page && state?.page) {
      setPage(state.page);
    } else if (!page) {
      navigate("/login");
    }
  }, []);

  const next = (obj) => {
    setNextStep(obj);
    setTimeout(() => {
      setCurrentStep(obj.step);
      setNextStep(null);
    }, 300);
  };

  const allSteps = [
    <Initiate
      language={language}
      animation={nextStep}
      next={next}
      def_focus={def_focus}
      page={page}
      setRegistrations={setRegistrations}
      setEmail={setEmail}
      setPhone={setPhone}
      setUserName={setUserName}
    />,
    <SelectPage
      language={language}
      animation={nextStep}
      next={next}
      page={page}
      registrations={registrations}
      setRegistration={setRegistration}
      setEmail={setEmail}
      setPhone={setPhone}
      setMessageResponse={setMessageResponse}
    />,
    <SuccessPage
      language={language}
      animation={nextStep}
      email={email}
      phone={phone}
      registration={registration}
    />,
    <CodePage
      language={language}
      animation={nextStep}
      def_focus={def_focus}
      registrations={registrations}
      userName={userName}
      messageResponse={messageResponse}
      setMessageResponse={setMessageResponse}
    />,
  ];

  return (
    <>
      <NavBar language={language} buttons={true} />
      <main className="public auth" style={{ padding: "2em 0" }}>
        <div className="card-public">
          <h1
            className="font-yongo login-heading"
            style={{
              textAlign: "center",
              marginBottom: "0.5em",
            }}
          >
            {page === "password"
              ? language.labels.authentication.password_forgot
              : language.labels.authentication.username_forgot}
          </h1>
          <div className="login-form" style={{ marginTop: "1rem" }}>
            {allSteps[currentStep]}
            {nextStep > -1 && allSteps[nextStep]}
          </div>
          <div className="join-container">
            <div
              role="link"
              className="link"
              style={{ fontSize: "0.9em", marginTop: "0.5em" }}
              onClick={() => returnToLogin()}
              onKeyUpCapture={(e) => e.key === "Enter" && returnToLogin()}
              tabIndex={0}
            >
              {language.labels.authentication.back_to_signin}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Forgot;
