import React, { useState, useRef } from "react";

import axiosCall from "../../../lib/axios";

const NewCommunity = ({ language, signout }) => {
  //////////// INITIALIZE ///////////
  const [newCommunity, setNewCommunity] = useState(false);
  const [err, setErr] = useState("");
  const [formError, setFormError] = useState(null);

  const uname = useRef();
  const license_type = useRef();
  const license_qty = useRef();
  const type = useRef();
  const private_community = useRef();
  const remittance = useRef();
  const verified = useRef();
  const ex_knowledge = useRef();

  const flexStyle = {
    marginTop: "1.5em",
    display: "flex",
    alignItems: "center",
    gap: "0.5em",
  };

  //////////// DATA ///////////
  const submitData = async () => {
    setErr("");

    setFormError("uname");
    uname.current.focus();

    //call api
  };

  //////////// GUI ///////////
  return (
    <div
      className="card-free"
      style={{
        paddingTop: "1.5em",
        paddingBottom: "1.5em",
        marginTop: "2em",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "0.5em",
          alignItems: "center",
        }}
      >
        <button className="button-glyph" onClick={() => setNewCommunity(true)}>
          +
        </button>
        <div>
          <span className="link" onClick={() => setNewCommunity(true)}>
            {language.labels.admin.communities.new}
          </span>
        </div>
      </div>
      {newCommunity && (
        <div style={{ padding: "1em 0" }}>
          <div style={flexStyle}>
            <div>{language.labels.admin.communities.headings.uname}</div>
            <input
              ref={uname}
              type="text"
              className={formError === "uname" && "error"}
            />
          </div>

          <div style={flexStyle}>
            <div>{language.labels.admin.communities.headings.license_type}</div>
            <select ref={license_type}>
              {["premium", "public", "expert"].map((license, index) => {
                return (
                  <option value={license}>
                    {language.labels.admin.communities.license_types[license]}
                  </option>
                );
              })}
            </select>
          </div>

          <div style={flexStyle}>
            <div>{language.labels.admin.communities.headings.license_qty}</div>
            <input ref={license_qty} type="number" />
          </div>

          <div style={flexStyle}>
            <div>{language.labels.admin.communities.headings.type}</div>
            <select ref={type}>
              {["institution", "public", "corporate"].map((type, index) => {
                return (
                  <option value={type}>
                    {language.labels.admin.communities.types[type]}
                  </option>
                );
              })}
            </select>
          </div>

          <div style={flexStyle}>
            <input ref={private_community} id="private" type="checkbox" />
            <label htmlFor="private">
              {language.labels.admin.communities.headings.private}
            </label>
          </div>

          <div style={flexStyle}>
            <input ref={remittance} id="remittance" type="checkbox" />
            <label htmlFor="remittance">
              {language.labels.admin.communities.headings.remittance}
            </label>
          </div>

          <div style={flexStyle}>
            <input ref={verified} id="verified" type="checkbox" />
            <label htmlFor="verified">
              {language.labels.admin.communities.headings.verified}
            </label>
          </div>

          <div style={flexStyle}>
            <input ref={ex_knowledge} id="ex_knowledge" type="checkbox" />
            <label htmlFor="ex_knowledge">
              {language.labels.admin.communities.headings.ex_knowledge}
            </label>
          </div>

          {err && (
            <div className="errtext" style={{ margin: "2em 0 -1.5em 0" }}>
              {err}
            </div>
          )}

          {/* buttons */}
          <div style={{ display: "flex", gap: "0.25em", margin: "3em 0" }}>
            <button
              className="button-secondary"
              onClick={() => setNewCommunity(false)}
            >
              {language.labels.app.cancel}
            </button>
            <button className="button" onClick={submitData}>
              {language.labels.app.apply}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default NewCommunity;
