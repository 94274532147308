import { React, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { NavBar } from "../widgets";
import { App, Footer, Hub } from "./sections";
import platform from "../../media/website/platform.jpg";
import background from "../../media/website/background/pexels-olly-712413-blur.jpg";
import appBackground from "../../media/website/background/pexels-fauxels-3228761.jpg";

const Platform = ({ language, os }) => {
  ////////////// INITIALIZE //////////////
  const navigate = useNavigate();

  const sections = ["app", "hub"];

  //scroll to #section or top
  useEffect(() => {
    const scrollToSection = async () => {
      try {
        let section = "";
        if (window.location.hash)
          section = window.location.hash.replace(/#/, "");
        scrollSection(section);
      } catch (err) {
        window.scrollTo(0, 0);
      }
    };
    scrollToSection();
  }, []);

  const scrollSection = (section) => {
    if (sections.includes(section)) {
      let pos = document.getElementById(section).getBoundingClientRect().top;
      window.scrollTo({
        top: pos + window.scrollY - 80,
        behavior: "smooth",
      });
    } else {
      window.scrollTo(0, 0);
    }
  };

  //scroll to get app
  const getApp = async () => {
    try {
      let pos = document.getElementById("getapp").getBoundingClientRect().top;
      window.scrollTo({
        top: pos + window.scrollY - 80,
        behavior: "smooth",
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <main>
      <NavBar language={language} buttons={true} clear={true} />
      {/**landing screen */}
      <div
        className="web-image-container"
        style={{
          backgroundImage: `url(${background})`,
          marginTop: "-80px",
        }}
      >
        <div className="public landing-screen">
          <h2
            className="heading2 font-size-XL font-white"
            style={{
              lineHeight: "initial",
              fontWeight: 500,
              marginBottom: "1em",
            }}
          >
            {language.website.platform.heading}
          </h2>

          <div className="flex-switch-900px reverse" style={{ gap: "2em" }}>
            <div
              style={{ flexBasis: "50%", flexShrink: "0", maxWidth: "480px" }}
            >
              <img
                className="phone-recording"
                style={{ border: "none", maxWidth: "unset", width: "100%" }}
                src={platform}
              />
            </div>

            <div className="font-white" style={{ marginTop: "-1em" }}>
              <div
                className="websection"
                dangerouslySetInnerHTML={{
                  __html: language.website.platform.blurb,
                }}
                style={{
                  fontSize: "1.1em",
                }}
              />

              <button
                className="demo-request-button"
                onClick={() => {
                  navigate("/contact");
                }}
                style={{ marginTop: "3em" }}
              >
                <div>{language.website.demo.request}</div>
                <span className="glyphs">{">"}</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* sections */}
      <div id="hub" />
      <Hub language={language} params={{}} />

      <div id="app" />
      <App language={language} params={{ background: appBackground, getApp }} />

      {/* footer */}
      <div id="getapp" />
      <Footer language={language} params={{ os, scrollSection }} />
    </main>
  );
};

export default Platform;
