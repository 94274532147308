import { React, useEffect } from "react";
import { NavBar } from "../widgets";
import { ContactForm, Footer } from "./sections";
import background from "../../media/website/background/pexels-olly-789822-blur.jpg";

const Contact = ({ language, os }) => {
  //scroll to top of page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main id="page-top">
      <NavBar language={language} buttons={true} clear={true} />

      <ContactForm language={language} params={{ background }} />

      <Footer language={language} params={{ os }} />
    </main>
  );
};

export default Contact;
