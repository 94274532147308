const supported = require("../locales/languages.json");

const getTranslator = () => {
  let locale;
  let labels;
  let website;

  locale = navigator.language;
  if (supported[locale]) {
    labels = require("../locales/" + supported[locale]);
    website = require("../locales/website/" + supported[locale]);
    return { labels: labels, lang: locale, locale, website };
  } else if (locale.length > 2 && supported[locale.substring(0, 2)]) {
    labels = require("../locales/" + supported[locale.substring(0, 2)]);
    website = require("../locales/website/" +
      supported[locale.substring(0, 2)]);
    return { labels: labels, lang: locale.substring(0, 2), locale, website };
  } else {
    labels = require("../locales/default.json");
    website = require("../locales/website/default.json");
    return { labels: labels, lang: "en", locale: "en", website };
  }
};

export { getTranslator };
