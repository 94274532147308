import { React, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Director = ({ language }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/hub");
  });

  return <main></main>;
};

export default Director;
