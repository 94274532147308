import { React, useEffect } from "react";
import { App, Footer } from "./sections";
import { NavBar } from "../widgets";

const GetApp = ({ language, os }) => {
  //scroll to top of page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <NavBar language={language} buttons={true} />

      <App language={language} params={{ personal: true, os }} />

      {/* bottom spacing and footer*/}
      <div style={{ height: "6em" }} />
      <Footer language={language} params={{}} />
    </main>
  );
};

export default GetApp;
