import { React } from "react";
import { useNavigate } from "react-router-dom";
import { GetApp } from "../../widgets";
import background from "../../../media/website/background/night-blur.jpg";
import osBackground from "../../../media/website/background/heshan.jpg";
import config from "../../../config";

const Footer = ({ language, params }) => {
  ////////////// NAVIGATION //////////////
  const navigate = useNavigate();

  const solutions = ["healthcare", "training", "education"];

  const goTo = (page, sub) => {
    let currPath = window.location.pathname;
    if (currPath === page) {
      if (sub) {
        params.scrollSection(sub);
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      if (sub) {
        navigate(page + "#" + sub);
      } else {
        navigate(page);
      }
    }
  };

  ////////////// RENDER GUI //////////////
  return (
    <>
      {/* get app */}
      {params?.os && (
        <div
          className="web-image-container"
          style={{
            backgroundImage: `url(${osBackground})`,
            backgroundColor: "transparent",
          }}
        >
          <div className="public websection" style={{ padding: "2em 0" }}>
            <div className="flex-switch-680px" style={{ alignItems: "center" }}>
              <div className="public-header-container">
                <h3
                  className="heading2 font-size-L"
                  style={{
                    fontWeight: 400,
                    lineHeight: "initial",
                  }}
                >
                  {language.labels.brand.available[params.os]}
                </h3>
              </div>
              <GetApp os={params.os} language={language} />
            </div>
          </div>
        </div>
      )}

      {/* true footer */}
      <div
        className="web-image-container"
        style={{
          paddingTop: "0",
          backgroundImage: `url(${background})`,
          minHeight: "67vh",
        }}
      >
        <div className="public websection">
          {/* page links */}
          <div className="web-footer">
            <button
              aria-label={language.labels.brand.name}
              onClick={() => goTo("/home")}
            >
              <div
                aria-hidden="true"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.25em",
                  alignItems: "flex-start",
                }}
              >
                <div className="glyphs" style={{ fontSize: "1.9em" }}>
                  Y
                </div>
                <div
                  className="font-logo"
                  style={{ fontSize: "1.4em", paddingTop: "0.25em" }}
                >
                  {language.labels.brand.name}
                </div>
              </div>
            </button>

            <div className="group">
              <div>
                {/* platform */}
                <button onClick={() => goTo("/platform")}>
                  {language.website.pub_bar.platform}
                </button>
                <button
                  onClick={() => goTo("/platform", "hub")}
                  style={{ fontWeight: 200, fontSize: "0.9em" }}
                >
                  {language.labels.brand.hub}
                </button>
                <button
                  onClick={() => goTo("/platform", "app")}
                  style={{ fontWeight: 200, fontSize: "0.9em" }}
                >
                  {language.website.platform.app}
                </button>
              </div>

              {/* solutions */}
              <div className="web-footer-paired">
                <button onClick={() => goTo("/solutions")}>
                  {language.website.pub_bar.solutions}
                </button>

                {solutions.map((solution, index) => (
                  <button
                    onClick={() => goTo("/solutions", solution)}
                    style={{ fontWeight: 200, fontSize: "0.9em" }}
                  >
                    {language.website.solutions[solution].heading}
                  </button>
                ))}
              </div>

              {/* pricing */}
              <div className="web-footer-paired">
                <button onClick={() => goTo("/pricing")}>
                  {language.website.pub_bar.pricing}
                </button>
                {config.plans.map((plan, index) => (
                  <button
                    onClick={() => goTo("/pricing", plan)}
                    style={{ fontWeight: 200, fontSize: "0.9em" }}
                  >
                    {language.website.pricing[plan].heading}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div
            className="font-white card-heading"
            style={{ fontSize: "0.8em", paddingTop: "2em", fontWeight: "200" }}
          >
            {language.website.copyright}
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
