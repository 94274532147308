import React from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config";

const SideBar = React.forwardRef(
  (
    { language, signout, auth, communities, community, resetMenu, dirty },
    ref
  ) => {
    ////////////// INITIALIZE //////////////
    const navigate = useNavigate();

    ////////////// COMMON PAGES //////////////
    let standardPages = [];

    if (communities > 1 || (communities > 0 && !community.id)) {
      standardPages.push({
        name: language.labels.navigation.community_select,
        icon: "r",
        route: "communityhub",
      });
    }

    standardPages.push({
      name: language.labels.navigation.community_start,
      icon: "n",
      route: "newcommunity",
    });

    // standardPages.push({
    //   name: language.labels.navigation.communities_all,
    //   icon: "o",
    //   route: "find_community",
    // });

    standardPages.push({
      name: language.labels.navigation.account,
      icon: "B",
      route: "account",
    });

    ////////////// COMMUNITY PAGES //////////////
    let communityPages = [
      {
        name: language.labels.navigation.pathways,
        icon: "p",
        route: "pathways",
      },
      {
        name: language.labels.navigation.bites,
        icon: "j",
        route: "bites",
      },
      {
        name: language.labels.navigation.videos,
        icon: "v",
        route: "videos",
      },
      {
        name: language.labels.navigation.knowledge,
        icon: "k",
        route: "knowledge",
      },
    ];

    if (!community?.draft)
      communityPages.push({
        name: language.labels.navigation.live_streams,
        icon: "a",
        route: "livestreams",
      });

    ////////////// ADMIN PAGES //////////////
    let adminPages = [
      {
        name: language.labels.navigation.flagged,
        icon: "^",
        route: "flagged",
      },
      {
        name: language.labels.navigation.community_managers,
        icon: "m",
        route: "personnel",
      },
      {
        name: language.labels.navigation.community_invites,
        icon: "E",
        route: "invites",
      },
    ];

    ////////////// NAVIGATION //////////////
    const goToPage = (target) => {
      //check that we aren't timed out
      let last_active = localStorage.getItem("last_updated");

      //record last page
      localStorage.setItem(
        "previous_page",
        window.location.pathname.substring(1)
      );

      if (Date.now() - last_active <= config.server.timeout) {
        resetMenu();
        //don't allow navigation from newpathway to pathways for consistency with conversations
        if (
          target !== "pathways" ||
          window.location.pathname.substring(1) !== "newpathway"
        ) {
          if (dirty) {
            if (window.confirm(language.labels.error.unsaved))
              navigate(`/${target}`);
          } else {
            navigate(`/${target}`);
          }
        }
      } else {
        signout();
      }
    };

    ////////////// RENDER GUI //////////////

    const standardList = standardPages.map((subpage, index) => (
      <div
        className={`menu-item ${
          window.location.pathname.substring(1) === subpage.route
            ? "active"
            : ""
        }`}
        key={index}
        onClick={() => goToPage(subpage.route)}
      >
        <div aria-hidden="true" className="menu-icon">
          {subpage.icon}
        </div>
        <div
          className="menu-label"
          role="link"
          onKeyUpCapture={(e) => e.key === "Enter" && goToPage(subpage.route)}
          tabIndex="0"
          title={subpage.name}
        >
          {subpage.name}
        </div>
      </div>
    ));

    const communityList = communityPages.map((subpage, index) => (
      <div
        className={`menu-item ${
          window.location.pathname.substring(1) === subpage.route
            ? "active"
            : ""
        }`}
        key={index}
        onClick={() => goToPage(subpage.route)}
      >
        <div aria-hidden="true" className="menu-icon">
          {subpage.icon}
        </div>
        <div
          className="menu-label"
          role="link"
          onKeyUpCapture={(e) => e.key === "Enter" && goToPage(subpage.route)}
          tabIndex="0"
          title={subpage.name}
        >
          {subpage.name}
        </div>
      </div>
    ));

    const adminList = adminPages.map((subpage, index) => (
      <>
        {(subpage.route !== "membership" || community.type === "public") &&
          (subpage.route !== "licenses" || community.type === "corporate") &&
          (subpage.route !== "customers" || community.type === "customer") &&
          (subpage.route !== "subscribers" ||
            community.type === "invitation" ||
            community.type === "service") && (
            <div
              className={`menu-item ${
                window.location.pathname.substring(1) === subpage.route
                  ? "active"
                  : ""
              }`}
              key={index}
              onClick={() => goToPage(subpage.route)}
            >
              <div aria-hidden="true" className="menu-icon">
                {subpage.icon}
              </div>
              <div
                className="menu-label"
                role="link"
                onKeyUpCapture={(e) =>
                  e.key === "Enter" && goToPage(subpage.route)
                }
                tabIndex="0"
                title={subpage.name}
              >
                {subpage.name}
              </div>
            </div>
          )}
      </>
    ));

    return (
      <nav className="sidebar-container" aria-label={language.labels.aria.menu}>
        <div className="sidebar left0" ref={ref}>
          <div className="sidebar-background left0"></div>
          <div className="logo-text">{language.labels.brand.hub}</div>

          <div className="sidebar-menu-container">
            {auth.admin ? (
              <div
                className={`menu-item`}
                onClick={() => {
                  goToPage("admin");
                }}
              >
                <div aria-hidden="true" className="menu-icon-app">
                  Y
                </div>
                <div
                  role="link"
                  className="menu-label"
                  onKeyUpCapture={(e) => e.key === "Enter" && goToPage("admin")}
                  tabIndex="0"
                  title={language.labels.app.admin}
                >
                  {language.labels.app.admin.toUpperCase()}
                </div>
              </div>
            ) : (
              <div
                className={`menu-item`}
                onClick={() => {
                  goToPage("gotoapp");
                }}
              >
                <div aria-hidden="true" className="menu-icon-app">
                  Y
                </div>
                <div
                  role="link"
                  className="menu-label"
                  onKeyUpCapture={(e) =>
                    e.key === "Enter" && goToPage("gotoapp")
                  }
                  tabIndex="0"
                  title={
                    localStorage.getItem("mobile_sso")
                      ? language.labels.app.launch_return
                      : language.labels.app.launch_go
                  }
                >
                  {localStorage.getItem("mobile_sso")
                    ? language.labels.app.launch_return
                    : language.labels.app.launch_go}
                </div>
              </div>
            )}
          </div>

          {community?.id &&
            community?.title &&
            community?.crest &&
            community?.uname && (
              <div className="sidebar-menu-container">
                <div
                  className={`menu-item ${
                    window.location.pathname.substring(1) === "mycommunity"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => goToPage("mycommunity")}
                >
                  <div
                    className="menu-crest"
                    role="img"
                    aria-label={language.labels.aria.logo.replace(
                      /{org}/g,
                      community.title
                    )}
                    style={{
                      width: "1.5em",
                      height: "1.5em",
                      backgroundImage: `url("${
                        config.server.storage.bucket + community.crest
                      }")`,
                    }}
                  ></div>
                  <div style={{ flexBasis: "calc(30px - 1.5em)" }}></div>
                  <div
                    className="menu-label"
                    role="link"
                    onKeyUpCapture={(e) =>
                      e.key === "Enter" && goToPage("mycommunity")
                    }
                    tabIndex="0"
                    title={community.uname}
                  >
                    {community.uname}
                  </div>
                </div>
                {communityList}
              </div>
            )}

          {config.levels.indexOf(community.role) > 2 && !community.draft && (
            <div className="sidebar-menu-container">{adminList}</div>
          )}

          <div className="sidebar-menu-container">
            {standardList}
            <div
              className={`menu-item`}
              onClick={() => {
                signout(false);
              }}
            >
              <div aria-hidden="true" className="menu-icon">
                X
              </div>
              <div
                role="link"
                className="menu-label"
                onKeyUpCapture={(e) => e.key === "Enter" && signout(false)}
                tabIndex="0"
                title={language.labels.navigation.signout}
              >
                {language.labels.navigation.signout}
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
);

export default SideBar;
