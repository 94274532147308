import axios from "axios";
import config from "../config";

const logoff = async (cid) => {
  console.log(cid);

  try {
    if (document.getElementById("loader"))
      document.getElementById("loader").style.display = "none";
    const configurationObject = {
      url: `${config.server.api}/signout/hub/${cid}`,
      method: "GET",
      withCredentials: true,
      credentials: "include",
    };
    await axios(configurationObject);
    window.location.href = "/home";
  } catch (error) {
    window.location.href = "/home";
  }
};

export { logoff };
