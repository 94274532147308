import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import { TopBar, SideBar } from "../layout";

import { getContext } from "../../lib/signin";
import config from "../../config";
import { Invite, NavBar } from "../widgets";

const Invitation = ({ language, signout, pageRefs, pageFunctions }) => {
  ///////////////// INITIALIZE /////////////////
  const auth = useSelector((state) => state.authentication.value);

  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});
  const [context, setContext] = useState({});

  const [invitation, setInvitation] = useState({});
  const [member, setMember] = useState(false);
  const [code, setCode] = useState("");
  const [cid, setCid] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const initialize = async (obj, id, role) => {
    try {
      setCommunities(obj);
      let _community = await getContext(id, role, obj);
      setCommunity(_community);
      checkInvitations();
    } catch (err) {
      console.log(err);
      signout();
    }
  };

  useEffect(() => {
    if (
      auth.agree_terms !== "x" &&
      auth.uid &&
      localStorage.getItem("communities")
    ) {
      let obj = JSON.parse(localStorage.getItem("communities"));
      initialize(obj, auth.community_id, auth.role);
    } else {
      checkInvitations();
    }
  }, []);

  const checkInvitations = () => {
    if (!sessionStorage.getItem("invitation")) {
      let params = new URLSearchParams(window.location.search);
      let obj = {};
      for (const p of params) {
        if (p[0] === "c") {
          obj.cid = p[1];
        } else if (p[0] === "i") {
          obj.code = p[1];
        }
      }
      if (obj.cid && obj.code) {
        sessionStorage.setItem("invitation", JSON.stringify(obj));
        setCid(obj.cid);
        setCode(obj.code);
      } else {
        setErrMsg(language.labels.error.no_invite);
      }
    } else {
      const _obj = JSON.parse(sessionStorage.getItem("invitation"));
      if (_obj.cid && _obj.code) {
        setCid(_obj.cid);
        setCode(_obj.code);
      } else {
        setErrMsg(language.labels.error.no_invite);
      }
    }
  };

  useEffect(() => {
    if (code && cid) {
      getInvitation(code, cid);
    }
  }, [code, cid]);

  //get invitation
  const getInvitation = async (code, cid) => {
    try {
      setMember(cid === auth.community_id);

      const configurationObject = {
        url: config.server.api + "/invite/view",
        method: "POST",
        data: { code, cid },
      };
      document.getElementById("loader").style.display = "inline";
      const response = await axios(configurationObject);
      document.getElementById("loader").style.display = "none";
      if (response.status === 200) {
        setInvitation(response.data.invitation);
        setContext(response.data.community);
      } else {
        setErrMsg(language.labels.error.no_invite);
        sessionStorage.removeItem("invitation");
      }
    } catch (err) {
      setErrMsg(language.labels.error.unknown);
      document.getElementById("loader").style.display = "none";
      sessionStorage.removeItem("invitation");
    }
  };

  ///////////////// RENDER GUI /////////////////
  return (
    <>
      {auth.account_type ? (
        <div className="main" ref={pageRefs._main_}>
          <div
            className="sr-only"
            id="primary_focus"
            tabIndex={0}
            onBlur={(e) => e.target.setAttribute("tabIndex", -1)}
          ></div>
          <div
            ref={pageRefs.skip_link}
            className="link sr-only"
            role="link"
            onClick={() => pageFunctions.skipLink(false)}
            onKeyUpCapture={(e) =>
              e.key === "Enter" && pageRefs.main_focus.current.focus()
            }
            onFocus={() => pageFunctions.skipLink()}
            onBlur={() => pageFunctions.skipLink(false)}
            tabIndex={0}
          >
            {language.labels.aria.skip}
          </div>
          <TopBar language={language} toggleMenu={pageFunctions.toggleMenu} />
          <SideBar
            language={language}
            signout={signout}
            auth={auth}
            communities={communities.length}
            community={community}
            resetMenu={pageFunctions.resetMenu}
            ref={pageRefs.sidebar_ref}
          />
          <Invite
            language={language}
            auth={auth}
            signout={signout}
            params={{
              main_focus: pageRefs.main_focus,
              cid,
              code,
              context,
              errMsg,
              invitation,
              member,
              setCommunities,
              setErrMsg,
            }}
          />
        </div>
      ) : (
        <>
          <NavBar language={language} buttons={true} />
          <Invite
            language={language}
            auth={auth}
            signout={signout}
            params={{
              main_focus: pageRefs.main_focus,
              cid,
              code,
              context,
              errMsg,
              invitation,
              member,
              setCommunities,
              setErrMsg,
            }}
          />
        </>
      )}
    </>
  );
};

export default Invitation;
